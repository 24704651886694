import { Modal, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { getContacts, logActivity, updateEvidence } from "../../../../api";
import { useAuth } from "../../../../contexts/AuthContext";
import { getDateFormat } from "../../../../utils/date-format";

import {
  Form,
  SimpleItem,
  GroupItem,
  EmptyItem,
} from "devextreme-react/ui/form";
import { DevexEditors } from "../../../../utils/devex-editors";
import ToolBarItems from "../../../../components/ToolBarItems";
import CollapseSection from "../../../../components/CollaspeSection";
import { useParams } from "react-router-dom";
import { Template } from "devextreme-react/core/template";
import ContactSelectBoxItem from "../../../../components/SelectBoxItems/ContactSelectBoxItem.js";
import { useQueries } from "@tanstack/react-query";
import EvidenceAPI from "../../../../api/evidence";
import LocationsAPI from "../../../../api/locations";
import Loader from "../../../../components/Loader.js";
import { safeParseJSON } from "../../../../utils/File-parsers/safeParseJSON.js";

const EditEvidenceModal = ({
  open,
  handleClose = () => {},
  onEditSubmit = (e) => {},
  defaultEvidenceInfo,
}) => {
  const { case_id } = useParams();
  const [itemType, setItemType] = useState(
    defaultEvidenceInfo?.evi_type_id || null
  );
  const { currentUser } = useAuth();
  const form = useRef(null);
  const formSection = useRef(null);
  const customFieldForm = useRef(null);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const currentItemType = itemType || defaultEvidenceInfo?.evi_type_id || null;

  const handleSubmit = async (event) => {
    if (
      form.current.instance.validate().isValid &&
      customFieldForm.current.instance.validate().isValid
    ) {
      const formData = Object.assign(
        {},
        {
          ...form.current.instance.option("formData"),
          ...customFieldForm.current.instance.option("formData"),
        }
      );

      const formatData = (data) => {
        return Object.entries(data)
          .map(([key, value]) => {
            key = key
              .split("_")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize first letter of each word
              )
              .join(" ");
            if (value === null) {
              value = "";
            }
            return `${key}: ${value}`;
          })
          .join("\n");
      };

      handleClose();

      let oldData = defaultEvidenceInfo;

      let newData = formData;

      let updateData = {};

      const previousDataForLogging = {};
      const updatedCustomFieldValuesForLogging = {};

      // Compare old data to form data, add changes to updateData
      for (let key of Object.keys(newData)) {
        if (oldData[key] !== newData[key]) {
          updateData[key] = newData[key];
          previousDataForLogging[key] = oldData[key];
        }
      }
      //Update New Evi Type ID with Evi Type Name
      if (updateData.evi_type_id) {
        updateData.item_type = fieldData.types.find(
          (t) => t.evi_type_id === updateData.evi_type_id
        ).evi_type;
      }
      //these values are not required for updating the evidence
      delete updateData.evi_type_id;

      const arraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) return false;
        }
        return true;
      };

      //Get custom attributes from updateData object
      //Compile them into a single object array with related attrubite values
      //remove original form items from form data
      let customAttributes = [];
      for (let key of Object.keys(updateData)) {
        if (key.indexOf("custom_attribute_") !== -1) {
          let field_id = parseInt(key.replace("custom_attribute_", ""));
          const baseAttr = fieldData.customFields.find(
            (attr) => attr.field_id === field_id
          );
          let currentAttr = oldData.custom_attributes.find((attribute) => {
            return attribute.field_id === field_id;
          });
          let valuesAreEqual = false;
          const currentValue = safeParseJSON(currentAttr?.value);
          const newValue = safeParseJSON(updateData[key]);
          if (Array.isArray(currentValue) && Array.isArray(newValue)) {
            valuesAreEqual = arraysEqual(currentValue, newValue);
          } else {
            valuesAreEqual = currentValue === newValue;
          }

          if (!valuesAreEqual) {
            previousDataForLogging[`${baseAttr.field_name}`] = safeParseJSON(
              currentAttr?.value || ""
            );

            updatedCustomFieldValuesForLogging[`${baseAttr.field_name}`] =
              safeParseJSON(updateData[key]);

            customAttributes.push({
              attribute_id: currentAttr ? currentAttr.attribute_id : null,
              field_id: field_id,
              field_name: baseAttr.field_name,
              item_id: oldData.evidence_id,
              value: updateData[key],
              editor_type: baseAttr.editor_type,
              description: baseAttr.description,
              options: baseAttr.options || null,
            });
          }
          delete updateData[key];
          delete previousDataForLogging[key];
        }
      }
      //
      const updateDataForLogging = {
        ...updateData,
        ...updatedCustomFieldValuesForLogging,
      };

      if (customAttributes.length > 0)
        updateData.custom_attributes = customAttributes;

      // if updateData empty, do not update
      if (Object.keys(updateData).length > 0) {
        const editResult = await updateEvidence(
          defaultEvidenceInfo.evidence_id,
          updateData
        );
        enqueueSnackbar(
          `Edited Evidence Item ${defaultEvidenceInfo.evidence_number}`,
          {
            variant: "success",
          }
        );
        logActivity(
          defaultEvidenceInfo.case_id,
          currentUser.user_id,
          `Edited Evidence ${
            defaultEvidenceInfo.evidence_number
          }\n\nPrevious:\n${formatData(
            previousDataForLogging
          )}\n\nNew:\n${formatData(updateDataForLogging)}
            `
        );
        onEditSubmit({
          evidenceInfo: defaultEvidenceInfo,
          updateData,
          newAttributes: editResult.newAttributes,
        });
      } else {
        enqueueSnackbar(`No new info to update evidence.`, {
          variant: "warning",
        });
      }
    }
  };

  const results = useQueries({
    queries: [
      {
        queryKey: ["evidence:customFields"],
        queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
        enabled: open,
        select: (data) => data?.filter((c) => c.enabled === 1),
      },
      {
        queryKey: ["evidence:types"],
        queryFn: () => EvidenceAPI.getEvidenceTypes(),
        enabled: open,
      },
      {
        queryKey: ["evidence:providers"],
        queryFn: () => EvidenceAPI.getEvidenceBrands(),
        enabled: open,
      },
      {
        queryKey: ["evidence:locations", { include_groups: false }],
        queryFn: () => LocationsAPI.getLocations({}, { include_groups: false }),
        enabled: open,
      },
      {
        queryKey: [
          "people:contacts",
          {
            case_id: defaultEvidenceInfo
              ? defaultEvidenceInfo.case_id
              : parseInt(case_id),
          },
        ],
        queryFn: () =>
          getContacts({
            case_id: defaultEvidenceInfo
              ? defaultEvidenceInfo.case_id
              : parseInt(case_id),
          }),
        enabled: open,
      },
    ],
  });

  const isDone = results.every((r) => r.isFetched);

  // use fieldData as convenience object to access query results
  const fieldData = {
    customFields: results[0]?.data || [],
    evidenceTypes: results[1]?.data || [],
    evidenceProviders: results[2]?.data || [],
    evidenceLocations: results[3]?.data || [],
    submitters: [],
    contacts: results[4]?.data || [],
  };

  // construct default data to form when rendered to user
  const defaultFormData = {
    evidence_number: defaultEvidenceInfo?.evidence_number || null,
    item_type: defaultEvidenceInfo?.item_type || null,
    manufacturer: defaultEvidenceInfo?.manufacturer || null,
    model_name: defaultEvidenceInfo?.model_name || null,
    model_number: defaultEvidenceInfo?.model_number || null,
    serial_number: defaultEvidenceInfo?.serial_number || null,
    capacity: defaultEvidenceInfo?.capacity || null,
    capacity_unit: defaultEvidenceInfo?.capacity_unit || null,
    description: defaultEvidenceInfo?.description || null,
    location_received: defaultEvidenceInfo?.location_received || null,
    contact_id: defaultEvidenceInfo?.contact_id || null,
  };

  const customFormData = {};

  defaultEvidenceInfo?.custom_attributes?.forEach((attr) => {
    customFormData[`custom_attribute_${attr.field_id}`] =
      attr.editor_type === "tagBox" ? safeParseJSON(attr.value) : attr.value;
  });

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      disableEnforceFocus={true}
      style={{
        userSelect: "none",
        zIndex: 1400,
      }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 700,
          maxHeight: "90vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 350px)",
          overflowY: "auto",
          padding: 20,
          borderRadius: 1,
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>Edit Evidence</div>
        {isDone ? (
          <>
            <CollapseSection
              title="Evidence Details"
              visible={true}
              onInitialized={(e) => {
                formSection.current = e.component;
              }}
            >
              <Form ref={form} colCount={2} defaultFormData={defaultFormData}>
                <SimpleItem
                  dataField="evidence_number"
                  label={{ text: "Evidence Number" }}
                  isRequired={true}
                  editorOptions={{
                    hint: "Enter a unique ID that identifies this evidence.",
                    placeholder: "Leave blank to auto-generate evidence number",
                  }}
                />
                <SimpleItem
                  dataField="item_type"
                  label={{ text: "Evidence Type" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.evidenceTypes,
                    placeholder: "Select Evidence Type...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "evi_type",
                    valueExpr: "evi_type",
                    searchEnabled: true,
                    onItemClick: (e) => {
                      setTimeout(() => {
                        setItemType(e.itemData.evi_type_id);
                      }, 25);
                    },
                  }}
                />
                <SimpleItem
                  dataField="manufacturer"
                  label={{ text: "Item Brand" }}
                  editorType="dxAutocomplete"
                  editorOptions={{
                    dataSource: fieldData.evidenceProviders,
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    placeholder: "Apple, Google, Samsung...",
                    displayExpr: "manufacturer",
                    valueExpr: "manufacturer",
                    searchEnabled: true,
                    acceptCustomValue: true,
                  }}
                />
                <SimpleItem
                  dataField="model_name"
                  label={{ text: "Item Name" }}
                  editorOptions={{
                    placeholder: "iPhone Xs, WINSRV003, Smith Email Account...",
                    hint: "Enter the model name of the device.",
                  }}
                />
                <SimpleItem
                  dataField="model_number"
                  label={{ text: "Model Number or Service" }}
                  editorOptions={{
                    placeholder: "A1234, WD2500, GMail...",
                    hint: "Enter the model number for this item.",
                  }}
                />
                <SimpleItem
                  dataField="serial_number"
                  label={{ text: "Unique Identifier" }}
                  editorOptions={{
                    placeholder: "first.last@email.com, serial number...",
                    hint: "Enter a unique identifier for this item.",
                  }}
                />
                <GroupItem colCount={2} cssClass="dxform-group-no-padding">
                  <SimpleItem
                    dataField="capacity"
                    label={{ text: "Size" }}
                    editorType="dxNumberBox"
                    editorOptions={{
                      min: 0,
                      max: 999,
                      showSpinButtons: true,
                    }}
                  />
                  <SimpleItem
                    dataField="capacity_unit"
                    label={{ text: "Size Unit" }}
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: ["KB", "MB", "GB", "TB", "PB"],
                    }}
                  />
                </GroupItem>
                <SimpleItem
                  dataField="contact_id"
                  label={{ text: "Linked Contact" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.contacts,
                    placeholder: "Select Contact...",
                    dropDownOptions: {
                      maxHeight: 300,
                    },
                    displayExpr: "name",
                    valueExpr: "client_id",
                    searchEnabled: true,
                    showClearButton: true,
                    openOnFieldClick: false,
                    onFocusIn: (e) => {
                      e.component.open();
                    },
                    itemTemplate: "contact_id",
                  }}
                />
                <Template
                  name="contact_id"
                  render={(data) => <ContactSelectBoxItem data={data} />}
                />
                <SimpleItem
                  dataField="location_received"
                  label={{ text: "Location Recieved" }}
                  editorOptions={{
                    placeholder: "Address, Front Office, etc...",
                    hint: "Location at time of intake.",
                  }}
                />
                <EmptyItem />
                <SimpleItem
                  dataField="description"
                  label={{ text: "Description" }}
                  colSpan={2}
                  editorType="dxTextArea"
                  editorOptions={{
                    height: 135,
                    placeholder:
                      "Enter a brief description of this item and any important details.",
                  }}
                />
              </Form>
            </CollapseSection>
            <CollapseSection title="Custom Fields">
              <Form
                ref={customFieldForm}
                colCount={2}
                defaultFormData={customFormData}
              >
                {fieldData.customFields &&
                  fieldData.customFields
                    // If custom field not associated with evidence type, don't show it
                    .filter(
                      (r) =>
                        r?.type_ids?.length === 0 ||
                        (currentItemType &&
                          r?.type_ids?.includes?.(currentItemType))
                    )
                    .map((attribute) => {
                      return (
                        <SimpleItem
                          key={`custom_attribute_${attribute.field_id}`}
                          dataField={`custom_attribute_${attribute.field_id}`}
                          editorType={DevexEditors[attribute.editor_type]}
                          isRequired={attribute.is_required === 1}
                          label={{ text: attribute.field_name }}
                          editorOptions={{
                            placeholder: attribute.description || "",
                            hint: attribute.description || "",
                            items: JSON.parse(attribute.options || "[]"),
                            dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
                            useMaskBehavior: true, // for datebox
                            displayFormat: getDateFormat({
                              isMoment: false,
                              includeTime: false,
                            }), //returns string defining how date should be rendered to user
                            showClearButton:
                              attribute.editor_type !== "textBox", // X button to entry, allows deleting dates
                            pickerType: "calendar", //render format for datebox
                            showDropDownButton: true,
                            multiline: false, //only applies to tagbox, keeps multiple values on one line
                            showSelectionControls:
                              attribute.editor_type === "tagBox" ? true : false, //only applies to tagbox, renders a checkbox
                          }}
                        />
                      );
                    })}
              </Form>
            </CollapseSection>
            <ToolBarItems
              submitText="Edit Evidence"
              onCancel={handleClose}
              onSubmit={handleSubmit}
              style={{ marginTop: 20 }}
            />
          </>
        ) : (
          <Loader />
        )}
      </div>
    </Modal>
  );
};

export default EditEvidenceModal;

import { httpDelete, httpGet, httpPost } from "../connection";
import axios from "axios";
import readFileAsBase64 from "../../utils/readFileAsBase64";

export async function getEvidencePhotos(values = { evidence_id: null }) {
  return await httpGet(`/evidence/items/${values.evidence_id}/photos`);
}

const uploadEvidencePhoto = async (values = {}) => {
  const { evidence_id, ...rest } = values;
  return await httpPost(
    `/evidence/items/${values.evidence_id}/upload-photo`,
    rest
  );
};

async function uploadFile(values = {}) {
  // Check if URL is AWS S3
  if (
    values.url.includes("amazonaws.com") ||
    values.url.includes("backblazeb2.com")
  ) {
    return await axios.put(values.url, values.file, {
      onUploadProgress: (progressEvent) => {
        values?.onUploadProgress?.({
          uploadPercentage: Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          ),
          total: progressEvent.total,
        });
      },
    });
  }

  // Upload to Monolith API
  else {
    // Construct form data object
    const formData = new FormData();
    formData.append("file", values.file);

    // Upload file
    return await axios.put(values.url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        values?.onUploadProgress?.({
          uploadPercentage: Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          ),
          total: progressEvent.total,
        });
      },
    });
  }
}

export async function uploadEvidencePhotos(
  values = { evidence_id: null, caseInfo: "", photoPaths: [] }
) {
  const requests = [];

  for (const file of values.photoPaths) {
    let buffer = await readFileAsBase64(file);

    requests.push(
      httpPost(`/evidence/items/${values.evidence_id}/photos`, {
        filename: file.name,
        caseInfo: values.caseInfo,
        data: buffer,
      })
    );
  }

  return await Promise.all(requests);
}

export async function deleteEvidencePhotos(
  values = { evidence_id: null, image_id: null }
) {
  return await httpDelete(`/evidence/photos/${values.image_id}`);
}

const createEvidencePhoto = async (values = {}) => {
  const { evidence_id, ...rest } = values;
  return await httpPost(`/evidence/items/${evidence_id}/photos`, rest);
};

const EvidencePhotoAPI = {
  getEvidencePhotos,
  uploadEvidencePhotos,
  deleteEvidencePhotos,
  uploadEvidencePhoto,
  uploadFile,
  createEvidencePhoto,
};

export default EvidencePhotoAPI;

import moment from "moment";

import convertCustomFieldsToObject from "../../components/Forms/utils/convertCustomFieldsToObject.js";
import {
  MonolithUIProvider,
  useMonolithUITheme,
} from "@monolith-forensics/monolith-ui";
import TestComponent from "./TestComponent.jsx";
import { ThemeProvider } from "styled-components";

const testCaseData = {
  case_id: 1612,
  case_number: "123-45",
  case_name: "Edit this case",
  client_id: 1,
  case_type: "Consultation",
  case_status: "Closed",
  case_lead_id: 1,
  case_open_date: moment().format("YYYY-MM-DD"),
  case_synopsis:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  custom_attributes: [
    {
      value: '["item 1"]',
      item_id: 1612,
      type_id: null,
      field_id: 69,
      field_name: "This is a Tagbox",
      editor_type: "tagBox",
      attribute_id: 92,
      custom_field_69: '["item 1"]',
    },
    {
      value: '["X-Ways", "Axiom"]',
      item_id: 1612,
      type_id: null,
      field_id: 59,
      field_name: "Software Used",
      editor_type: "tagBox",
      attribute_id: 91,
      custom_field_59: '["X-Ways"]',
    },
    {
      value: "test 2",
      item_id: 1612,
      type_id: null,
      field_id: 55,
      field_name: "Time Allowed",
      editor_type: "textBox",
      attribute_id: 84,
      custom_field_55: "test 2",
    },
    {
      value: "2024-05-08",
      item_id: 1612,
      type_id: null,
      field_id: 56,
      field_name: "Request Date",
      editor_type: "dateBox",
      attribute_id: 83,
      custom_field_56: "2024-05-08",
    },
    {
      value: "2024-05-14",
      item_id: 1612,
      type_id: null,
      field_id: 65,
      field_name: "Purge Date",
      editor_type: "dateBox",
      attribute_id: 81,
      custom_field_65: "2024-05-14",
    },
    {
      value: "test 1",
      item_id: 1612,
      type_id: null,
      field_id: 67,
      field_name: "Test Field",
      editor_type: "textBox",
      attribute_id: 80,
      custom_field_67: "test 1",
    },
    {
      value: "High",
      item_id: 1612,
      type_id: null,
      field_id: 57,
      field_name: "Priority",
      editor_type: "dropDown",
      attribute_id: 79,
      custom_field_57: "High",
    },
  ],
};

const testCaseDefaultFormData = {
  case_id: testCaseData.case_id,
  uuid: testCaseData.uuid,
  case_number: testCaseData.case_number,
  case_name: testCaseData.case_name,
  case_type: testCaseData.case_type,
  case_status: testCaseData.case_status,
  case_open_date: testCaseData.case_open_date,
  case_synopsis: testCaseData.case_synopsis,
  client_id: testCaseData.client_id,
  case_lead_id: testCaseData.case_lead_id,
  ...convertCustomFieldsToObject(testCaseData.custom_attributes),
};

const testEvidenceData = {
  evidence_id: 16503,
  evidence_number: "EVI-00246",
  created_on: "2024-05-12T21:39:12.330Z",
  item_type: "Smartphone",
  manufacturer: "Apple",
  model_number: "A4512",
  model_name: "iPhone 13 Pro Max",
  serial_number: "QAUW72HD8CHQI8",
  capacity: 128,
  capacity_unit: "GB",
  description: "This is a new device.",
  location_id: -1,
  is_parent: 1,
  progress: "New Progress",
  status: null,
  case_id: 1609,
  uuid: "vqGQqnwxE1SHVAfmHhMktQ",
  location_received: null,
  last_audit_date: null,
  capacity_bytes: 137438953472,
  capacity_gb: 128,
  parent_name: null,
  parent_uuid: null,
  parent_id: null,
  case_ref: "Report Template Testing",
  case_number: "MF-24-0133_05_10",
  client_id: 1,
  case_lead_id: 1,
  case_uuid: "hVg79Tt3AmwZRZ9UiywV9e",
  case_storage_path: "MF-24-0133_05_10 - Report Template Testing",
  assigned_user_ids: [1],
  case_lead: {
    name: "Matt Danner",
    email: "matt@monolithforensics.com",
    title: "Senior Consultant",
    last_name: "Danner",
    first_name: "Matt",
    case_lead_id: 1,
    organization: "Monolith Forensics",
  },
  client_organization: "Springfield Nuclear Power Plant",
  client_name: "Homer Simpson",
  evi_type_id: 1,
  intake_date: "2024-05-09T23:05:00.000Z",
  received_by: "Matt Danner",
  received_from: "Homer Simpson",
  is_acquired: "Yes",
  acquisition_count: 2,
  has_photos: "Yes",
  photo_count: 3,
  has_children: "No",
  child_count: 0,
  is_parent_child: "Parent",
  location_name: "Destroyed",
  office_id: null,
  location_group_name: null,
  location_group_id: null,
  location_path: null,
  office_name: null,
  contact_name: null,
  contact_id: 30,
  linked_contact: {
    name: null,
    client_id: null,
  },
  item_id: 16503,
  custom_attributes: [
    {
      value: "123456789",
      enabled: 1,
      item_id: 16503,
      field_id: 71,
      type_ids: [1],
      field_name: "IMEI",
      sort_value: -0.6875,
      editor_type: "textBox",
      attribute_id: 353,
      custom_field_71: "123456789",
    },
    {
      value: "This is test data",
      enabled: 1,
      item_id: 16503,
      field_id: 68,
      type_ids: null,
      field_name: "Evidence Test",
      sort_value: 0.3125,
      editor_type: "textBox",
      attribute_id: 354,
      custom_field_68: "This is test data",
    },
    {
      value: "Search Warrant",
      enabled: 1,
      item_id: 16503,
      field_id: 45,
      type_ids: null,
      field_name: "Authority",
      sort_value: 1.25,
      editor_type: "dropDown",
      attribute_id: 355,
      custom_field_45: "Search Warrant",
    },
    {
      value: '["Item 1","Item 2","Item 3"]',
      enabled: 1,
      item_id: 16503,
      field_id: 72,
      type_ids: null,
      field_name: "TagBox Test",
      sort_value: 3.1875,
      editor_type: "tagBox",
      attribute_id: 356,
      custom_field_72: '["Item 1","Item 2","Item 3"]',
    },
    {
      value: "No",
      enabled: 1,
      item_id: 16503,
      field_id: 40,
      type_ids: null,
      field_name: "Bio-Hazard",
      sort_value: 2.1875,
      editor_type: "dropDown",
      attribute_id: 357,
      custom_field_40: "No",
    },
  ],
};

const testEvidenceDefaultFormData = {
  evidence_id: testEvidenceData.evidence_id,
  uuid: testEvidenceData.uuid,
  case_id: testEvidenceData.case_id,
  evidence_number: testEvidenceData.evidence_number,
  item_type: testEvidenceData.item_type,
  manufacturer: testEvidenceData.manufacturer,
  model_name: testEvidenceData.model_name,
  model_number: testEvidenceData.model_number,
  serial_number: testEvidenceData.serial_number,
  capacity: testEvidenceData.capacity,
  capacity_unit: testEvidenceData.capacity_unit,
  contact_id: testEvidenceData.contact_id,
  description: testEvidenceData.description,
  location_received: testEvidenceData.location_received,
  ...convertCustomFieldsToObject(testEvidenceData.custom_attributes),
};

const MonolithUIContent = ({}) => {
  const { theme } = useMonolithUITheme();
  return (
    <ThemeProvider theme={theme}>
      <TestComponent />
    </ThemeProvider>
  );
};

const MonolithUI = ({}) => {
  return (
    <MonolithUIProvider>
      <MonolithUIContent />
    </MonolithUIProvider>
  );
};

export default MonolithUI;

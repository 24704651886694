import styled from "styled-components";

const AuditDetailsHeader = styled(({ className, auditData }) => {
  return <div className={className}>{auditData?.name || ""}</div>;
})`
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

export default AuditDetailsHeader;

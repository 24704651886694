import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Typography, useTheme } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import styled from "styled-components";
import { getContacts } from "../../api";
import { usePermissions } from "../../hooks/usePermissions";
import Loader from "../../components/Loader";
import ContactOverview from "./ContactOverview";
import TaskButton from "../../Monolith-UI/TaskButton";
import { DeleteContactModal } from "@/components/Modals";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const ContactDetails = () => {
  const theme = useTheme();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const { client_id } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data: contactInfo, isLoading } = useQuery({
    queryKey: ["contact", "item", client_id],
    queryFn: () => getContacts({ client_id }),
    select: (res) => res[0],
  });

  const queryClient = useQueryClient();

  const handleContactUpdate = () => {
    queryClient.refetchQueries({
      queryKey: ["contact", "item", client_id],
    });
  };

  if (isLoading) return <Loader />;

  return (
    <>
      {contactInfo && (
        <>
          <Helmet>
            <title>Contact Details | {contactInfo.name}</title>
          </Helmet>
          <ContactHeader>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              <BackLink message={"Back to Contacts"} />
              <ContactName>
                {contactInfo.name || "Contact Details"}
                <Typography color="textSecondary" variant="subtitle2">
                  {contactInfo.organization || ""}
                </Typography>
              </ContactName>
            </div>
            {hasPermission(MONOLITH_PERMISSIONS.CONTACTS_DELETE) && (
              <DeleteButton onClick={() => setShowDeleteModal(true)}>
                <DeleteOutlineOutlinedIcon
                  style={{
                    color: theme.palette.error.main,
                  }}
                />
                <div className="action-menu-label">Delete</div>
              </DeleteButton>
            )}
          </ContactHeader>
          <ContactOverview
            contactInfo={contactInfo}
            handleContactUpdate={handleContactUpdate}
          />
          <DeleteContactModal
            show={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onCancel={() => setShowDeleteModal(false)}
            onSubmit={() => {}}
            defaultInfo={contactInfo}
          />
        </>
      )}
    </>
  );
};

const ContactHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0;
  justify-content: space-between;
`;

const ContactName = styled.div`
  margin: 0 10px;
  font-weight: bold;
  font-size: 20px;
`;

const BackLink = styled(({ message, ...props }) => {
  const theme = useTheme();
  let linkTo = `/contacts`;

  // Check if user clicked from inside a case, return to that case
  if (window.history?.state?.usr?.from?.includes("case:contacts")) {
    linkTo = `/cases/${window.history.state.usr.case_id}/contacts`;
  }

  return (
    <Link to={linkTo} {...props}>
      <TaskButton
        title={message}
        style={{ outline: `1px solid ${theme.palette.divider}` }}
      >
        <KeyboardReturnOutlinedIcon style={{ fontSize: 15 }} />
      </TaskButton>
    </Link>
  );
})`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: small;
  margin-left: auto;
  margin-bottom: 15px;

  &:hover {
    text-decoration: underline;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  margin: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }

  svg {
    font-size: 18px;
    margin-right: 5px;
    padding-bottom: 2px;
  }
`;

export default ContactDetails;

import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import PassIndicator from "../../../components/PassIndicator.js";
import { usePermissions } from "../../../hooks/usePermissions";
import Table, { Column, useTable } from "../../../Monolith-UI/Table/Table.js";
import { getDateFormat, monolithMoment } from "../../../utils/date-format";
import ComboButton from "../../../Monolith-UI/ComboButton/ComboButton.js";
import { useEffect, useState } from "react";
import synchronizeColumnState from "../../../utils/synchronize-column-state.js";
import { TextBox } from "devextreme-react/ui/text-box.js";

import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

import { useSnackbar } from "notistack";
import { useDebouncedCallback } from "use-debounce";
import AuditsAPI from "@/api/Audits/Audits";

const stateStoreKey = "audits:logs:list";

const columnDefs = [
  {
    dataField: "created_on",
    caption: "Timestamp",
    render: (data) =>
      monolithMoment({ timestamp: data.created_on, includeTime: true }),
  },
  {
    dataField: "object_name",
    caption: "Audit Item",
    render: (data) => <AuditItemCell rowData={data} />,
  },
  {
    dataField: "created_by",
    caption: "User",
    render: (data) => data.created_by.full_name,
  },
  {
    dataField: "passed_audit",
    caption: "Audit Status",
    render: (data) => <PassIndicator passed={data.passed_audit} />,
  },
  { dataField: "notes", caption: "Notes", allowSorting: false },
];

export const ItemTotal = styled(
  ({ className, total, Icon = ArticleOutlinedIcon }) => {
    return (
      <div className={className} title="Total Items">
        <Icon
          style={{
            fontSize: 14,
            marginRight: 5,
          }}
        />
        <div
          style={{
            fontSize: 12,
          }}
        >
          {total || 0}
        </div>
      </div>
    );
  }
)`
  display: flex;
  align-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.text.secondary};
  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const AuditItemCell = styled(({ className, rowData }) => {
  const href =
    rowData.object_type === "evidence"
      ? `/evidence/${rowData.object_uuid}/overview`
      : `/storage/${rowData.object_uuid}`;

  return (
    <div className={className}>
      <Link to={href}>
        <div className="label">{rowData?.object_name}</div>
      </Link>
    </div>
  );
})`
  .label {
    font-weight: 500;
    color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.palette.text.primary};
    }
  }
`;

const resolveSort = (cols) => {
  let [sort] = cols
    ?.filter((c) => c.sorting?.active)
    .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

  return sort;
};

const AuditLogs = styled(({ className, ...rest }) => {
  const { uuid } = useParams();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [columnState, setColumnState] = useState(() => {
    return synchronizeColumnState(
      columnDefs,
      JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
    );
  });

  const [query, setQuery] = useState({
    audit_uuid: uuid,
    page: 1,
    pageSize: 100,
    order: resolveSort(columnState),
  });

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [stateStoreKey, query],
      queryFn: ({ pageParam }) =>
        AuditsAPI.getAuditLogs({ ...query, page: pageParam }),
      getNextPageParam: (lastPage, pages) => {
        return lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage, pages) => {
        if (firstPage.page - 1 === 0) return null;
        return firstPage.page - 1;
      },
      initialPageParam: 1,
      enabled: !!query,
      placeholderData: (data) => data,
    });

  const records = data?.pages?.reduce((acc, page) => {
    return [...acc, ...page.data];
  }, []);

  const totalRecords = data?.pages?.[0]?.total || 0;

  const debouncedFetchNextPage = useDebouncedCallback((e) => {
    fetchNextPage();
  }, 50);

  const table = useTable();

  const handleRefresh = () => {
    queryClient.refetchQueries({
      queryKey: [
        stateStoreKey,
        {
          ...query,
        },
      ],
    });
  };

  const handleSort = (field) => {
    const savedColumn =
      columnState?.find((svc) => field === svc.dataField) || {};
    const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

    let newOrder = null;

    // if sorted on a different column, reset the sort
    if (query?.order?.field !== field) {
      newOrder = {
        field,
        sort: "asc",
      };
    }

    // otherwise rotate the sort options on the current column
    else {
      switch (order) {
        case "asc":
          newOrder = {
            field,
            sort: "desc",
          };
          break;
        case "desc":
          newOrder = null;
          break;
        default:
          newOrder = {
            field,
            sort: "asc",
          };
      }
    }

    let newCols = columnState?.map((c) => {
      if (c.dataField === field) {
        return {
          ...c,
          sorting: {
            active: newOrder ? true : false,
            direction: newOrder?.sort,
          },
        };
      }
      delete c.sorting;
      return c;
    });

    let newQuery = {
      ...query,
      order: newOrder,
    };

    setColumnState(newCols);

    setQuery(newQuery);
  };

  const handleColumnVisibility = (column, visible) => {
    setColumnState((cs) => {
      return cs.map((c) => {
        if (c.dataField === column.dataField) {
          return {
            ...c,
            visible,
          };
        }
        return c;
      });
    });
  };

  const handleExportCaseTable = () => {
    // show snackbar
    enqueueSnackbar("Exporting table...", {
      variant: "info",
    });

    AuditsAPI.exportAuditLogsList({
      query: {
        ...query,
      },
      type: "xlsx",
      columns: columnState
        .filter((c) => c.visible !== false)
        .sort((a, b) => a.order - b.order)
        .map((c) => {
          return { dataField: c.dataField, header: c.caption, ...c };
        }),
      date_format: getDateFormat({ isMoment: true, includeTime: true }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      custom_date_format: getDateFormat({ isMoment: true, includeTime: false }),
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
      // remove snackbar
      el.remove();
    });
  };
  // Detect scroll to bottom
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 100 * data?.pages?.length) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  // Sync column state to local storage
  useEffect(() => {
    let oldState = JSON.parse(localStorage.getItem(stateStoreKey)) || {};
    localStorage.setItem(
      stateStoreKey,
      JSON.stringify({
        ...oldState,
        cols: columnState,
      })
    );
  }, [columnState]);

  return (
    <div className={className}>
      <div
        style={{
          display: "flex",
          flex: "initial",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          marginBottom: 10,
          marginTop: 20,
        }}
      >
        <ItemTotal total={totalRecords || 0} Icon={ArticleOutlinedIcon} />
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            minWidth: "fit-content",
          }}
        >
          <ComboButton
            type="multi-select"
            data={columnState.filter((c) => c.showInColumnChooser !== false)}
            displayField="caption"
            idField={"dataField"}
            selectedItems={columnState.filter((c) => c.visible !== false)}
            variant="outlined"
            closeOnSelect={false}
            showSearch={true}
            dropDownTitle={() => {
              return (
                <div
                  style={{
                    margin: "5px 0px",
                    padding: 3,
                    color: theme.palette.text.secondary,
                    display: "flex",
                    alignItems: "center",
                    minWidth: 200,
                  }}
                >
                  Select Columns
                </div>
              );
            }}
            onItemDeSelect={(item) => {
              handleColumnVisibility(item, false);
            }}
            onItemSelect={(item) => {
              handleColumnVisibility(item, true);
            }}
            textColor={theme.palette.text.secondary}
            title={"Select Columns"}
          >
            <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Export Table"}
            onClick={handleExportCaseTable}
          >
            <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={table.isCompact ? "Zoom In" : "Zoom Out"}
            onClick={() => table.toggleCompact()}
          >
            {table.isCompact && (
              <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
            )}
            {!table.isCompact && (
              <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
            )}
          </ComboButton>
          <ComboButton
            type="button"
            variant={"outlined"}
            textColor={
              table.isStriped
                ? theme.palette.primary.main
                : theme.palette.text.secondary
            }
            title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
            onClick={() => table.toggleStripes()}
          >
            <ReorderOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Refresh Table"}
            onClick={handleRefresh}
          >
            <SyncOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <TextBox
            stylingMode="filled"
            placeholder="Search Logs"
            labelMode="static"
            height={30}
            style={{ marginLeft: "10px" }}
            onKeyUp={(e) => {
              let searchText = e.event.currentTarget.value;
              if (
                e.event.code === "Enter" ||
                e.event.code === "NumpadEnter" ||
                searchText === ""
              ) {
                setQuery((q) => ({
                  ...q,
                  search: searchText === "" ? null : searchText,
                  page: 1,
                }));
              }
            }}
          />
        </div>
      </div>
      <Table
        data={records || []}
        totalRecords={totalRecords}
        keyValue="uuid"
        tableInstance={table}
        columnProps={{ minWidth: 150, width: 150 }}
        onHeaderClick={(col) =>
          col?.sorting?.enabled === false ? null : handleSort(col.dataField)
        }
        allowColumnResize={false}
        allowColumnReorder={false}
        onScroll={handleScroll}
      >
        {columnState.map((col) => {
          return <Column key={col.dataField} {...col} />;
        })}
      </Table>
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export default AuditLogs;

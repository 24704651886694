import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { Link, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import { Button, useTheme } from "@mui/material";

import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

//Devextreme Components
import { TextBox } from "devextreme-react/ui/text-box";

import {
  getEvidence,
  createEvidenceMultiIntakePDF,
  createEvidenceMultiReleasePDF,
} from "../../../api";
import { getDateFormat, monolithMoment } from "../../../utils/date-format";
import { usePermissions } from "../../../hooks/usePermissions";
import MoveItemsPopup from "./components/popups/MoveItemsPopup";
import ReleaseItemsPopup from "./components/popups/ReleaseItemsPopup";
import DestroyItemsPopup from "./components/popups/DestroyItemsPopup";
import CreateEvidenceModal from "../../Evidence/components/Popups/CreateEvidenceModal";
import EditEvidenceModal from "../../Evidence/components/Popups/EditEvidenceModal";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import styled from "styled-components";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import IntakeItemsPopup from "./components/popups/IntakeItemsPopup";
import EvidenceAPI from "../../../api/evidence";
import {
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import TableColumnDefs from "../../../components/Evidence/TableColumnDefs.js";
import EvidenceDimensions from "../../../components/Evidence/EvidenceDimensions.js";
import { addCustomFieldsToColumnDefs } from "../../../components/Evidence/CustomFieldCell.js";
import LocationsAPI from "../../../api/locations/index.js";
import OfficesApi from "../../../api/offices/index.js";
import ClientsAPI from "../../../api/clients/index.js";
import { useQueryFilter } from "../../../Monolith-UI/QueryFilter/QueryFilter.js";
import ComboButton from "../../../Monolith-UI/ComboButton/ComboButton.js";
import Table, { Column, useTable } from "../../../Monolith-UI/Table/Table.js";
import Flyout, { FlyoutHeader } from "../../../Monolith-UI/Flyout/Flyout.js";
import TaskButton from "../../../Monolith-UI/TaskButton.js";
import Loader from "../../../components/Loader.js";
import DeleteEvidencePopup from "../../../components/Evidence/DeleteEvidencePopup.js";
import { nanoid } from "nanoid";
import synchronizeColumnState from "../../../utils/synchronize-column-state.js";
import { useDebouncedCallback } from "use-debounce";
import MigrateEvidenceModal from "./components/MigrateItemsModal.js";
import CasesApi from "../../../api/cases/index.js";
import { CustomField } from "@/pages/Settings/CustomFieldSettings/types";
import {
  ColumnResize,
  Condition,
  SharedCaseTabListQuery,
} from "../types/Cases";
import { Evidence } from "@/pages/Evidence/types/Evidence";
import { CustomAttribute } from "@/types";
import {
  ColumnProps,
  PageData,
  TableColumn,
} from "@/Monolith-UI/Table/types/Table";

interface SelectionState {
  caseInfo: {
    case_id: number;
    uuid: string;
    case_number: string;
    case_ref: string;
  };
  options: {
    selectionState: string;
    included: string[];
    excluded: string[];
    query?: SharedCaseTabListQuery["query"];
  };
  total: number;
}

interface UpdateCache {
  rowData: Partial<Evidence>;
  updateData:
    | {
        progress: string;
        progress_id: number;
      }
    | {};
}

interface QueryFilter {
  clear: () => void;
}

export const ItemTotal = styled(
  ({ className, total, Icon = DevicesOutlinedIcon, style = {} }) => {
    return (
      <div className={className} title="Total Items" style={style}>
        <Icon
          style={{
            fontSize: 14,
            marginRight: 5,
          }}
          size={14}
        />
        <div
          style={{
            fontSize: 12,
          }}
        >
          {total || 0}
        </div>
      </div>
    );
  }
)`
  display: flex;
  align-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.text.secondary};
  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

export const SelectedItems = styled(({ className, selected }) => {
  return (
    <div className={className} title="Selected Items">
      <CheckOutlinedIcon
        style={{
          fontSize: 14,
          marginRight: 5,
        }}
      />
      <div
        style={{
          fontSize: 12,
        }}
      >
        {selected || 0}
      </div>
    </div>
  );
})`
  display: flex;
  align-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.text.secondary};
  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const columnDefs = TableColumnDefs.map((column) => {
  if (column.dataField === "case_number") {
    column.visible = false;
  }
  return column;
});

const CaseEvidence = styled(
  ({ className, stateStoreKey = "case:evidence:list" }) => {
    const { case_id } = useParams();
    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const { enqueueSnackbar } = useSnackbar();
    const [showCreateEvidenceModal, setShowCreateEvidenceModal] =
      useState(false);
    const [showMigrateModal, setShowMigrateModal] = useState(false);
    const [selectionState, setSelectionState] = useState<SelectionState>();
    const deleteEvidencePopup = useRef<any>(null);
    const moveItemsPopup = useRef<any>(null);
    const releaseItemsPopup = useRef<any>(null);
    const destroyItemsPopup = useRef<any>(null);
    const intakeItemsPopup = useRef<any>(null);
    const queryClient = useQueryClient();
    const theme = useTheme();
    // fix this before pr
    const queryFilter: MutableRefObject<QueryFilter | null> =
      useRef<QueryFilter>(null);
    const [pageSize, setPageSize] = useState(100);
    const [selectionTotal, setSelectionTotal] = useState(0);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [flyoutData, setFlyoutData] = useState<Evidence | null>(null);

    const { data: caseInfo } = useQuery({
      queryKey: [
        "cases",
        "list",
        {
          case_id: case_id ? parseInt(case_id) : null,
        },
      ],
      queryFn: () =>
        CasesApi.getCases({
          case_id: case_id ? parseInt(case_id) : null,
        }),
      select: (data) => data?.[0] || {},
    });

    const table = useTable();

    const [columnState, setColumnState] = useState(() => {
      return synchronizeColumnState(
        columnDefs,
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });

    let filterDimensions = EvidenceDimensions;

    const currentSort = useMemo(() => {
      let [sort] = columnState
        ?.filter((c) => c.sorting?.active)
        .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

      return sort;
    }, [columnState]);

    const [query, setQuery] = useState<SharedCaseTabListQuery | undefined>(
      undefined
    );

    const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery({
        queryKey: [
          stateStoreKey,
          {
            query: {
              ...query?.query,
              case_id,
              order: query?.query?.order || currentSort,
              pageSize,
            },
          },
        ],
        queryFn: ({ pageParam }) =>
          EvidenceAPI.query({
            query: {
              ...query?.query,
              case_id,
              order: query?.query?.order || currentSort,
              pageSize,
              page: pageParam,
            },
          }),
        getNextPageParam: (lastPage) => {
          return lastPage.nextPage;
        },
        getPreviousPageParam: (firstPage) => {
          if (firstPage.page - 1 === 0) return null;
          return firstPage.page - 1;
        },
        initialPageParam: 1,
        enabled: !!query,
        placeholderData: (data) => data,
      });

    const records: Evidence[] = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    const totalRecords = data?.pages?.[0]?.total || 0;

    const debouncedFetchNextPage = useDebouncedCallback((e?) => {
      fetchNextPage();
    }, 50);

    const { data: customFields } = useQuery({
      queryKey: ["evidence:customFields"],
      queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
    });

    useQueries({
      queries: [
        {
          queryKey: ["evidence:types"],
          queryFn: () => EvidenceAPI.getEvidenceTypes(),
          enabled: !!data && !!customFields,
        },
        {
          queryKey: ["evidence:providers"],
          queryFn: () => EvidenceAPI.getEvidenceBrands(),
          enabled: !!data && !!customFields,
        },
        {
          queryKey: ["evidence:progress"],
          queryFn: () => EvidenceAPI.getEvidenceProgress(),
          enabled: !!data && !!customFields,
        },
        {
          queryKey: ["evidence:locations", { include_groups: false }],
          queryFn: () =>
            LocationsAPI.getLocations({}, { include_groups: false }),
          enabled: !!data && !!customFields,
        },
        {
          queryKey: ["offices:list"],
          queryFn: () => OfficesApi.getOffices(),
          enabled: !!data && !!customFields,
        },
        {
          queryKey: ["clients:list"],
          queryFn: () => ClientsAPI.getClients(),
          enabled: !!data && !!customFields,
        },
        {
          queryKey: ["clients:organizations"],
          queryFn: () => ClientsAPI.getOrganizations(),
          enabled: !!data && !!customFields,
        },
      ],
    });

    // update filter dimensions with custom fields
    filterDimensions = useMemo(() => {
      if (customFields) {
        //update filter dimensions
        return [
          ...EvidenceDimensions,
          ...customFields.map((field: CustomField) => ({
            name: field.field_name,
            field: `custom_field_${field.field_id}`,
            id: `custom_field_${field.field_id}`,
            type:
              field.editor_type === "dateBox"
                ? "date"
                : field.editor_type === "dropDown" ||
                  field.editor_type === "tagBox"
                ? "string"
                : "text",
            mode:
              field.editor_type === "dateBox"
                ? "date"
                : field.editor_type === "dropDown" ||
                  field.editor_type === "tagBox"
                ? "multi-select"
                : "text",
            options: field.options
              ? JSON.parse(field.options as string).map((o: string) => ({
                  option: o,
                }))
              : null,
            selectionDisplayName: field.options ? "option" : null,
            selectionIdField: field.options ? "option" : null,
          })),
        ];
      }
      return EvidenceDimensions;
    }, [customFields]);

    const handleFilter = (newFilter: Condition) => {
      setQuery((q) => {
        return {
          query: { ...q?.query, ...newFilter, page: 1, pageSize, case_id },
        };
      });
    };
    const handleColumnVisibility = (
      column: ColumnProps<Evidence>,
      visible: boolean
    ) => {
      setColumnState((cs) => {
        const currentColumn = cs.find((c) => c.dataField === column.dataField);
        if (currentColumn) {
          return cs.map((c) => {
            if (c.dataField === column.dataField) {
              return {
                ...c,
                visible,
              };
            }
            return c;
          });
        } else {
          return [
            ...cs,
            {
              ...column,
              visible,
            },
          ];
        }
      });
    };
    const handleExportTable = () => {
      // show snackbar
      enqueueSnackbar("Exporting evidence table...", {
        variant: "info",
      });

      EvidenceAPI.exportEvidenceList({
        query: {
          ...query?.query,
          order: query?.query?.order || currentSort,
          pageSize,
        },
        type: "xlsx",
        columns: columnState
          .filter((c) => c.visible !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat({
          isMoment: true,
          includeTime: true,
        }),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );
        el.download = filename;
        el.click();

        // remove element
        el.remove();

        // remove snackbar
      });
    };
    const handleReload = () => {
      refetch();
    };
    const handleClearFilters = () => {
      queryFilter?.current?.clear();
    };
    const handleSort = (field: string) => {
      const savedColumn =
        columnState?.find((svc) => field === svc.dataField) || {};
      const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

      let newOrder = null;

      // if sorted on a different column, reset the sort
      if (query?.query?.order?.field !== field) {
        newOrder = {
          field,
          sort: "asc",
        };
      }

      // otherwise rotate the sort options on the current column
      else {
        switch (order) {
          case "asc":
            newOrder = {
              field,
              sort: "desc",
            };
            break;
          case "desc":
            newOrder = null;
            break;
          default:
            newOrder = {
              field,
              sort: "asc",
            };
        }
      }

      let newCols = columnState?.map((c) => {
        if (c.dataField === field) {
          return {
            ...c,
            sorting: {
              active: newOrder ? true : false,
              direction: newOrder?.sort,
            },
          };
        }
        delete c.sorting;
        return c;
      });

      let newQuery = {
        query: {
          ...query?.query,
          order: newOrder,
        },
      };

      setColumnState(newCols);

      setQuery(newQuery);
    };
    const handleColumnReorder = (
      newOrder: { column: string; order: number }[]
    ) => {
      setColumnState((cs) => {
        return newOrder.map((o) => {
          return {
            ...cs.find((c) => c.dataField === o.column),
            order: o.order,
          };
        });
      });
    };
    const handleColumnResize = (e: ColumnResize) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          const col = e.columns.find((col) => col.dataField === c.dataField);
          if (col) {
            return {
              ...c,
              width: parseInt(col.width.replace(/px/g, "")),
            };
          }
          return c;
        });
      });
    };
    const handleActionButtonClick = (rowData: Evidence) => {
      setIsFlyoutVisible(true);
      setFlyoutData(rowData);
    };
    const handleGetNextItem = (uuid: string) => {
      const current = records?.findIndex((i) => i.uuid === uuid);
      const nextItem = records[current + 1] || records[0];

      setFlyoutData(nextItem);
    };
    const handleGetPrevItem = (uuid: string) => {
      const current = records?.findIndex((i) => i.uuid === uuid);
      const prevItem = records[current - 1] || records[records.length - 1];

      setFlyoutData(prevItem);
    };
    const handleRowSelection = ({
      virtualSelectionTotal,
    }: {
      virtualSelectionTotal: number;
    }) => {
      setSelectionTotal(virtualSelectionTotal);
    };
    const handleEditFinished = (e: {
      evidenceInfo: Evidence;
      newAttributes: string[];
      updateData: Partial<Evidence>;
    }) => {
      refetch();
      queryClient.refetchQueries({
        queryKey: [
          "evidence:list",
          { evidence_id: e.evidenceInfo.evidence_id },
        ],
      });
    };
    const updateCache = (
      updateDetails: UpdateCache = { rowData: {}, updateData: {} }
    ) => {
      const itemUUID = updateDetails.rowData.uuid;
      queryClient.setQueryData(
        [
          stateStoreKey,
          {
            query: {
              ...query?.query,
              case_id,
              order: query?.query?.order || currentSort,
              pageSize,
            },
          },
        ],
        (data: PageData<Evidence>) => {
          if (!data) return null;
          const newPages = data?.pages.map((page) => {
            return {
              ...page,
              data: page?.data?.map((item) => {
                if (item.uuid === itemUUID) {
                  return {
                    ...item,
                    ...updateDetails.updateData,
                  };
                }
                return item;
              }),
            };
          });

          return {
            ...data,
            pages: newPages,
          };
        }
      );
    };
    const handleEvidenceDeleted = () => {
      setIsFlyoutVisible(false);
      setFlyoutData(null);
      refetch();
    };
    const handleEvidenceAction = (e: string) => {
      const selectedRows = table?.getVirtualSelectionTotal() || 0;
      if (selectedRows === 0) {
        enqueueSnackbar("Please select at least one evidence item.", {
          variant: "warning",
        });
        return;
      }

      switch (e) {
        case "Intake Selected Items":
          intakeItemsPopup.current.setSelectionState({
            total: selectionTotal,
            caseInfo: {
              case_id: caseInfo.case_id,
              case_number: caseInfo.case_number,
              case_ref: caseInfo.case_ref,
            },
            options: {
              selectionState: table?.getSelectionState() || null,
              included:
                table
                  ?.getSelectedRows()
                  .filter((r: Evidence) => !r.intake_date) //Ignore items that already have an intake record
                  .map((r: Evidence) => r.uuid) || [],
              excluded:
                table?.getExcludedRows().map((r: Evidence) => r.uuid) || [],
              ...query,
            },
          });
          intakeItemsPopup?.current.instance.show();
          break;
        case "Move Selected Items":
          moveItemsPopup.current.setSelectionState({
            total: selectionTotal,
            caseInfo: {
              case_id: caseInfo.case_id,
              case_number: caseInfo.case_number,
              case_ref: caseInfo.case_ref,
            },
            options: {
              selectionState: table?.getSelectionState() || null,
              included:
                table
                  ?.getSelectedRows()
                  .filter((r: Evidence) => r.location_id !== 1) //Ignore items that are released
                  .map((r: Evidence) => r.uuid) || [],
              excluded:
                table?.getExcludedRows().map((r: Evidence) => r.uuid) || [],
              ...query,
            },
          });
          moveItemsPopup.current.instance.show();
          break;
        case "Release Selected Items":
          releaseItemsPopup.current.setSelectionState({
            total: selectionTotal,
            caseInfo: {
              case_id: caseInfo.case_id,
              case_number: caseInfo.case_number,
              case_ref: caseInfo.case_ref,
            },
            options: {
              selectionState: table?.getSelectionState() || null,
              included:
                table
                  ?.getSelectedRows()
                  .filter((r: Evidence) => r.location_id !== 1) //Ignore items that are released
                  .map((r: Evidence) => r.uuid) || [],
              excluded:
                table?.getExcludedRows().map((r: Evidence) => r.uuid) || [],
              ...query,
            },
          });
          releaseItemsPopup.current.instance.show();
          break;
        case "Destroy Selected Items":
          destroyItemsPopup.current.setSelectionState({
            total: selectionTotal,
            caseInfo: {
              case_id: caseInfo.case_id,
              case_number: caseInfo.case_number,
              case_ref: caseInfo.case_ref,
            },
            options: {
              selectionState: table?.getSelectionState() || null,
              included:
                table
                  ?.getSelectedRows()
                  .filter((r: Evidence) => r.location_id !== 1) //Ignore items that are released
                  .map((r: Evidence) => r.uuid) || [],
              excluded:
                table?.getExcludedRows().map((r: Evidence) => r.uuid) || [],
              ...query,
            },
          });
          destroyItemsPopup.current.instance.show();
          break;
        case "Migrate Selected Items":
          setSelectionState({
            total: selectionTotal,
            caseInfo: {
              case_id: caseInfo.case_id,
              uuid: caseInfo.uuid,
              case_number: caseInfo.case_number,
              case_ref: caseInfo.case_ref,
            },
            options: {
              selectionState: table?.getSelectionState() || null,
              included:
                table?.getSelectedRows().map((r: Evidence) => r.uuid) || [],
              excluded:
                table?.getExcludedRows().map((r: Evidence) => r.uuid) || [],
              ...query,
            },
          });
          setShowMigrateModal(true);
          break;
        case "Create Intake Form - Selected Items":
          createEvidenceMultiIntakePDF({
            caseInfo: {
              case_id: caseInfo.case_id,
              case_number: caseInfo.case_number,
              case_ref: caseInfo.case_ref,
            },
            options: {
              selectionState: table?.getSelectionState() || null,
              included:
                table?.getSelectedRows().map((r: Evidence) => r.uuid) || [],
              excluded:
                table?.getExcludedRows().map((r: Evidence) => r.uuid) || [],
              ...query,
            },
          });
          break;
        case "Create Release Form - Selected Items":
          createEvidenceMultiReleasePDF({
            caseInfo: {
              case_id: caseInfo.case_id,
              case_number: caseInfo.case_number,
              case_ref: caseInfo.case_ref,
            },
            options: {
              selectionState: table?.getSelectionState() || null,
              included:
                table?.getSelectedRows().map((r: Evidence) => r.uuid) || [],
              excluded:
                table?.getExcludedRows().map((r: Evidence) => r.uuid) || [],
              ...query,
            },
          });
          break;
        default:
          break;
      }
    };
    const handleIntakeItemsSubmitted = () => {
      refetch();
    };
    const handleCreateFinished = () => {
      refetch();
    };
    const handleMoveItemsCompleted = () => {
      refetch();
    };
    const handleReleaseItemsCompleted = () => {
      refetch();
    };
    const handleDestroyItemsCompleted = () => {
      refetch();
    };
    // Detect scroll to bottom
    const handleScroll = (e: React.MouseEvent) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      const pagesLength = data?.pages?.length || 0;
      if (scrollHeight - scrollTop <= clientHeight + 100 * pagesLength) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };
    const clearSelections = () => {
      setSelectionTotal(0);
      table.clearSelections();
    };

    useEffect(() => {
      const oldStateStorageValue = localStorage.getItem(stateStoreKey);
      let oldState = oldStateStorageValue
        ? JSON.parse(oldStateStorageValue)
        : {};
      localStorage.setItem(
        stateStoreKey,
        JSON.stringify({
          ...oldState,
          cols: columnState,
        })
      );
    }, [columnState]);

    useEffect(() => {
      if (!customFields) return;
      // Add custom fields to column defs
      setColumnState((cs) => {
        return addCustomFieldsToColumnDefs(customFields, cs);
      });
    }, [customFields]);

    const { queryButton, conditions } = useQueryFilter({
      dimensions: filterDimensions.sort((a, b) => a.name.localeCompare(b.name)),
      onQuerySet: handleFilter,
      queryFilter: queryFilter,
      stateStoring: {
        enabled: true,
        type: "localStorage",
        storageKey: stateStoreKey,
      },
    });

    const bulkDropdownBuilder = () => {
      const dropdownOptions = [];
      const permissionsMap = {
        "Intake Selected Items": MONOLITH_PERMISSIONS.EVIDENCE_BULK_INTAKE,
        "Move Selected Items": MONOLITH_PERMISSIONS.EVIDENCE_BULK_MOVE,
        "Release Selected Items": MONOLITH_PERMISSIONS.EVIDENCE_BULK_RELEASE,
        "Destroy Selected Items": MONOLITH_PERMISSIONS.EVIDENCE_BULK_UPDATE,
        "Migrate Selected Items": MONOLITH_PERMISSIONS.EVIDENCE_BULK_UPDATE,
      };

      for (const [key, value] of Object.entries(permissionsMap)) {
        if (hasPermission(value)) {
          dropdownOptions.push(key);
        }
      }
      return dropdownOptions;
    };

    return (
      <div className={className}>
        <Helmet title="Case Evidence" />
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 5,
            marginTop: 5,
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_CREATE)}
            onClick={(e) => {
              setShowCreateEvidenceModal(true);
            }}
            style={{
              minWidth: "fit-content",
              fontSize: 11,
              padding: "3px 6px",
            }}
          >
            + New Evidence
          </Button>
          <div style={{ marginLeft: 10 }}>{queryButton}</div>
          <ItemTotal total={totalRecords || 0} />
          <SelectedItems selected={selectionTotal || 0} />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              minWidth: "fit-content",
            }}
          >
            {hasPermission() && (
              <ComboButton
                type="dropDown"
                data={[
                  ...bulkDropdownBuilder(),
                  "Create Intake Form - Selected Items",
                  "Create Release Form - Selected Items",
                ]}
                title={"More Actions"}
                showDropdownIcon={true}
                variant="outlined"
                useSelectMode={false}
                onItemSelect={handleEvidenceAction}
                textColor={theme.palette.text.primary}
              >
                Actions
              </ComboButton>
            )}
            <ComboButton
              type="multi-select"
              data={columnState.filter((c) => c.showInColumnChooser !== false)}
              displayField="caption"
              idField={"dataField"}
              selectedItems={columnState.filter((c) => c.visible !== false)}
              variant="outlined"
              closeOnSelect={false}
              showSearch={true}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Select Columns
                  </div>
                );
              }}
              onItemDeSelect={(item) => {
                handleColumnVisibility(item, false);
              }}
              onItemSelect={(item) => {
                handleColumnVisibility(item, true);
              }}
              textColor={theme.palette.text.secondary}
              title={"Select Columns"}
            >
              <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Export Table"}
              onClick={handleExportTable}
            >
              <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Clear Filters"}
              onClick={handleClearFilters}
            >
              <FilterListOffIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={table.isCompact ? "Zoom In" : "Zoom Out"}
              onClick={() => table.toggleCompact()}
            >
              {table.isCompact && (
                <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
              {!table.isCompact && (
                <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
            </ComboButton>
            <ComboButton
              type="button"
              variant={"outlined"}
              textColor={
                table.isStriped
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              }
              title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
              onClick={() => table.toggleStripes()}
            >
              <ReorderOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <TextBox
              stylingMode="filled"
              placeholder="Search Evidence"
              labelMode="static"
              height={30}
              style={{ marginLeft: "10px" }}
              onKeyUp={(e: any) => {
                let searchText = e.event.currentTarget.value;
                if (
                  e.event.code === "Enter" ||
                  e.event.code === "NumpadEnter" ||
                  searchText === ""
                ) {
                  setQuery((q) => ({
                    query: {
                      ...q?.query,
                      search: searchText === "" ? null : searchText,
                      page: 1,
                    },
                  }));
                }
              }}
            />
          </div>
        </div>
        {data && customFields && (
          <>
            {<div>{conditions}</div>}
            <Table
              data={records || []}
              reloadFn={handleReload}
              totalRecords={totalRecords || 0}
              tableInstance={table}
              keyValue="uuid"
              columnProps={{ minWidth: 150, width: 150 }}
              onScroll={handleScroll}
              onHeaderClick={(col: TableColumn<Evidence>) =>
                col?.sorting?.enabled === false
                  ? null
                  : handleSort(col.dataField)
              }
              onRowUpdated={(e: UpdateCache) => updateCache(e)}
              onColumnReorder={handleColumnReorder}
              onColumnResize={handleColumnResize}
              onActionButtonClick={handleActionButtonClick}
              onSelectionChanged={handleRowSelection}
              showSelection={true}
              selectAll={true}
              showActionColumn={true}
              focusedRow={flyoutData}
            >
              {columnState.map((col) => {
                return <Column key={col.dataField} {...col} />;
              })}
            </Table>
            {isFlyoutVisible && (
              <Flyout
                onClose={() => {
                  setIsFlyoutVisible(false);
                  setFlyoutData(null);
                }}
              >
                <FlyoutHeader>
                  <div style={{ width: "100%" }}>
                    <h4
                      style={{
                        margin: "5px 0px",
                        color: theme.palette.text.primary,
                      }}
                    >
                      {flyoutData?.evidence_number}
                    </h4>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          color: theme.palette.text.secondary,
                          fontSize: 12,
                        }}
                      >
                        Evidence Details
                      </div>
                      <div
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TaskButton
                          onClick={() => handleGetPrevItem(flyoutData?.uuid!)}
                          style={{
                            fontSize: 10,
                          }}
                          variant="outlined"
                        >
                          <KeyboardArrowLeftOutlinedIcon
                            style={{ fontSize: 17 }}
                          />
                        </TaskButton>
                        <TaskButton
                          onClick={() => handleGetNextItem(flyoutData?.uuid!)}
                          style={{
                            fontSize: 10,
                          }}
                          variant="outlined"
                        >
                          <KeyboardArrowRightOutlinedIcon
                            style={{ fontSize: 17 }}
                          />
                        </TaskButton>
                      </div>
                    </div>
                  </div>
                </FlyoutHeader>
                <EvidenceFlyoutContent
                  defaultEvidenceInfo={flyoutData}
                  onEditSubmit={handleEditFinished}
                  onEvidenceCopied={handleCreateFinished}
                  deleteEvidencePopup={deleteEvidencePopup}
                  setIsFlyoutVisible={setIsFlyoutVisible}
                  setFlyoutData={setFlyoutData}
                  reloadFn={handleReload}
                  columnDefs={columnState}
                />
              </Flyout>
            )}
          </>
        )}
        {!(data && customFields) && (
          <Loader message={"Retrieving Evidence..."} />
        )}
        {data && customFields && (
          <>
            <CreateEvidenceModal
              open={showCreateEvidenceModal}
              caseInfo={caseInfo}
              onSubmit={handleCreateFinished}
              handleClose={() => setShowCreateEvidenceModal(false)}
              // temp fix until create evidence modal is converted
              includeCaseField={undefined}
              defaultInfo={undefined}
            />
            <MigrateEvidenceModal
              open={showMigrateModal}
              handleClose={() => setShowMigrateModal(false)}
              onSubmit={() => {
                clearSelections();
                refetch();
              }}
              defaultInfo={{ selectionState, caseInfo }}
            />
          </>
        )}
        {useMemo(() => {
          return (
            <>
              <DeleteEvidencePopup
                deleteEvidencePopup={deleteEvidencePopup}
                onDelete={handleEvidenceDeleted}
              />
              <IntakeItemsPopup
                intakeItemsPopup={intakeItemsPopup}
                onSubmit={handleIntakeItemsSubmitted}
              />
              <MoveItemsPopup
                moveItemsPopup={moveItemsPopup}
                onComplete={handleMoveItemsCompleted}
              />
              <ReleaseItemsPopup
                releaseItemsPopup={releaseItemsPopup}
                onComplete={handleReleaseItemsCompleted}
              />
              <DestroyItemsPopup
                destroyItemsPopup={destroyItemsPopup}
                onComplete={handleDestroyItemsCompleted}
              />
            </>
          );
        }, [])}
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const EvidenceFlyoutContent = styled(
  ({
    className,
    defaultEvidenceInfo,
    deleteEvidencePopup,
    reloadFn,
    columnDefs,
    onEditSubmit = () => {},
    onEvidenceCopied = () => {},
  }) => {
    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const [showEditEvidenceModal, setShowEditEvidenceModal] = useState(false);
    const [showCopyEvidenceModal, setShowCopyEvidenceModal] = useState(false);
    const theme = useTheme();
    const queryClient = useQueryClient();

    const { data: evidenceInfo } = useQuery({
      queryKey: [
        "evidence:list",
        { evidence_id: defaultEvidenceInfo.evidence_id },
      ],
      queryFn: () =>
        getEvidence({ evidence_id: defaultEvidenceInfo.evidence_id }),
      initialData: [defaultEvidenceInfo],
      select: (data) => {
        return data[0];
      },
    });

    const handleDeleteEvidence = () => {
      deleteEvidencePopup.current.instance.setEvidenceInfo(evidenceInfo);
      deleteEvidencePopup.current.instance.show();
    };

    const handleChange = (data: Evidence) => {
      reloadFn();
      queryClient.setQueryData(
        ["evidence:list", { evidence_id: defaultEvidenceInfo.evidence_id }],
        (oldData: Evidence[]) => {
          const dataObj = oldData[0] || {};
          return [{ ...dataObj, ...data }];
        }
      );
    };

    const dataFieldIgnoreList = ["description"];

    const data = {
      ...evidenceInfo,
      ...(evidenceInfo?.custom_attributes?.reduce(
        (
          acc: { [key: string]: string | number | string[] },
          attr: CustomAttribute
        ) => {
          acc[`custom_field_${attr.field_id}`] = attr.value;
          return acc;
        },
        {}
      ) || {}),
    };

    const detail = (
      <div className="detail-container">
        {columnDefs
          .filter(
            (c: ColumnProps<Evidence>) =>
              !dataFieldIgnoreList.includes(c.dataField)
          )
          .filter((c: ColumnProps<Evidence>) =>
            c.dataField.includes("custom_field_") ? !!data[c.dataField] : true
          )
          .map((c: ColumnProps<Evidence>) => {
            return (
              <div className="detail-item" key={nanoid()}>
                <div className="detail-label">{c.caption}</div>
                {c.render ? (
                  c.render(data, { onChange: handleChange })
                ) : c.dataType === "date" ? (
                  <>
                    {monolithMoment({
                      timestamp: data[c.dataField],
                      includeTime: true,
                    })}
                  </>
                ) : (
                  <div className="detail-value">{data[c.dataField]}</div>
                )}
              </div>
            );
          })}
      </div>
    );

    return (
      <div className={className}>
        <div className="action-menu">
          <Link
            className={className}
            to={`/evidence/${evidenceInfo.uuid}/overview`}
            style={{ padding: 0, margin: 0 }}
          >
            <div className="action-menu-item">
              <LaunchOutlinedIcon
                style={{ color: theme.palette.primary.main }}
              />
              <div className="action-menu-label">View Evidence</div>
            </div>
          </Link>
          {hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_CREATE) && (
            <div
              className="action-menu-item"
              onClick={() => setShowCopyEvidenceModal(true)}
            >
              <ContentCopyOutlinedIcon
                style={{
                  color: theme.palette.primary.main,
                }}
              />
              <div className="action-menu-label">Copy</div>
            </div>
          )}
          {hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_UPDATE) && (
            <>
              <div
                className="action-menu-item"
                onClick={() => setShowEditEvidenceModal(true)}
              >
                <EditOutlinedIcon
                  style={{ color: theme.palette.primary.main }}
                />
                <div className="action-menu-label">Edit</div>
              </div>
              <div className="action-menu-item" onClick={handleDeleteEvidence}>
                <DeleteOutlineOutlinedIcon
                  style={{ color: theme.palette.error.main }}
                />
                <div className="action-menu-label">Delete</div>
              </div>
            </>
          )}
        </div>
        {detail}
        <div className="detail-label" style={{ marginTop: 30 }}>
          Description
        </div>
        <div style={{ position: "relative" }}>
          {useMemo(() => {
            return (
              <div className="description">{evidenceInfo.description}</div>
            );
          }, [evidenceInfo])}
        </div>
        {showEditEvidenceModal && (
          <EditEvidenceModal
            open={showEditEvidenceModal}
            onEditSubmit={onEditSubmit}
            handleClose={() => setShowEditEvidenceModal(false)}
            defaultEvidenceInfo={evidenceInfo}
          />
        )}
        {showCopyEvidenceModal && (
          <CreateEvidenceModal
            open={showCopyEvidenceModal}
            handleClose={() => setShowCopyEvidenceModal(false)}
            onSubmit={onEvidenceCopied}
            defaultInfo={evidenceInfo}
            caseInfo={{ case_id: evidenceInfo.case_id }}
            // temp fix until create evidence modal is converted
            includeCaseField={undefined}
          />
        )}
      </div>
    );
  }
)`
  padding: 20px;

  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .detail-container {
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 25px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: border 0.2s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

export default CaseEvidence;

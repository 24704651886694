import styled from "styled-components";

import {
  Column,
  Switch,
  Table,
  useMonolithUITheme,
} from "@monolith-forensics/monolith-ui";

const TestComponent = styled(({ className }) => {
  const { toggleColorScheme, colorScheme } = useMonolithUITheme();

  return (
    <div className={"TestComponent " + className}>
      <h1>Monolith UI</h1>
      <h3>Dark Mode</h3>
      <Switch checked={colorScheme === "DARK"} onChange={toggleColorScheme} />
      {/* <h3>Buttons</h3>
      <div style={{ display: "flex", flexDirection: "row", gap: 25 }}>
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <Button size="xs"> Default "xs"</Button>
          <Button size="sm"> Default "sm"</Button>
          <Button size="md"> Default "md"</Button>
          <Button size="lg"> Default "lg"</Button>
          <Button size="xl"> Default "xl"</Button>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <Button size="xs" variant="contained">
            Contained "xs"
          </Button>
          <Button size="sm" variant="contained">
            Contained "sm"
          </Button>
          <Button size="md" variant="contained">
            Contained "md"
          </Button>
          <Button size="lg" variant="contained">
            Contained "lg"
          </Button>
          <Button size="xl" variant="contained">
            Contained "xl"
          </Button>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <Button size="xs" variant="text">
            Text "xs"
          </Button>
          <Button size="sm" variant="text">
            Text "sm"
          </Button>
          <Button size="md" variant="text">
            Text "md"
          </Button>
          <Button size="lg" variant="text">
            Text "lg"
          </Button>
          <Button size="xl" variant="text">
            Text "xl"
          </Button>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <Button size="xs" variant="light">
            Light "xs"
          </Button>
          <Button size="sm" variant="light">
            Light "sm"
          </Button>
          <Button size="md" variant="light">
            Light "md"
          </Button>
          <Button size="lg" variant="light">
            Light "lg"
          </Button>
          <Button size="xl" variant="light">
            Light "xl"
          </Button>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <Button size="xs" variant="subtle" color="primary">
            Subtle "xs"
          </Button>
          <Button size="sm" variant="subtle" color="info">
            Subtle "sm"
          </Button>
          <Button size="md" variant="subtle" color="error">
            Subtle "md"
          </Button>
          <Button size="lg" variant="subtle" color="red">
            Subtle "lg"
          </Button>
          <Button size="xl" variant="subtle" color="yellow">
            Subtle "xl"
          </Button>
        </div>
      </div>
      <h3>Basic Form</h3>
      <Grid col={2} width={800}>
        <TextInput label="Text Input" />
        <SelectBox
          label="Select Box"
          data={[
            { label: "Item 1", value: 1, data: null },
            { label: "Item 2", value: 2, data: null },
            { label: "Item 3", value: 3, data: null },
          ]}
          // data={["Item 1", "Item 2", "Item 3"]}
          defaultValue={{ label: "Item 3", value: 3, data: null }}
        />
        <TagBox
          label="Tag Box"
          data={[
            { label: "Item 1", value: 1, data: null },
            { label: "Item 2", value: 2, data: null },
            { label: "Item 3", value: 3, data: null },
          ]}
          // data={["Item 1", "Item 2", "Item 3"]}
          defaultValue={[
            { label: "Item 1", value: 1, data: null },
            { label: "Item 2", value: 2, data: null },
            { label: "Item 3", value: 3, data: null },
          ]}
          allowCustomValue={true}
          clearable={true}
        />
        <DateInput label="Date Input" enableCalendar />
        <FileInputField colSpan={2} label="File Input" />
        <TextAreaInput colSpan={2} label="Text Area Input" />
      </Grid>
      <h3>Check Boxes</h3>
      <Grid col={4} width={800}>
        <CheckBox size="xs" />
        <CheckBox size="sm" />
        <CheckBox size="md" />
        <CheckBox size="lg" />
      </Grid> */}
      <h3>Table/Datagrid</h3>
      <Table
        data={[
          { id: 1, name: "John Doe", age: 30, address: "1234 Elm St." },
          { id: 2, name: "Jane Smith", age: 25, address: "1234 Elm St." },
          { id: 3, name: "John Doe", age: 30, address: "1234 Elm St." },
          { id: 4, name: "Jane Smith", age: 25, address: "1234 Elm St." },
          { id: 5, name: "John Doe", age: 30, address: "1234 Elm St." },
          { id: 6, name: "Jane Smith", age: 25, address: "1234 Elm St." },
          { id: 7, name: "John Doe", age: 30, address: "1234 Elm St." },
          { id: 8, name: "Jane Smith", age: 25, address: "1234 Elm St." },
          { id: 9, name: "John Doe", age: 30, address: "1234 Elm St." },
          { id: 10, name: "Jane Smith", age: 25, address: "1234 Elm St." },
        ]}
        onRowDoubleClick={(row) => console.log(row)}
      >
        This is a test
        <Column dataField="id" caption="ID" />
        <Column dataField="name" caption="Name" />
        <Column dataField="age" caption="Age" />
        <Column dataField="address" caption="Address" />
      </Table>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 20px;

  height: 0px;
  width: 100%;

  padding: 20px;

  background-color: ${(props) => props.theme.palette.background.default};
`;

export default TestComponent;

import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import AuditsAPI from "@/api/Audits/Audits";
import styled, { useTheme } from "styled-components";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { ExternalLink, CheckIcon, Maximize2 } from "lucide-react";
import { useState } from "react";
import { Button, Modal, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { usePermissions } from "../../../hooks/usePermissions";
import { useEffect, useRef } from "react";
import { useDebouncedCallback } from "use-debounce";
import { getDateFormat, monolithMoment } from "../../../utils/date-format";
import ToolBarItems from "../../../components/ToolBarItems.js";
import { useSnackbar } from "notistack";
import { getIconForFile } from "vscode-icons-js";
import { useBarcodeScanner } from "../../../components/BarcodeScanner.js";

import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import EvidenceFlyout from "../../../Monolith-UI/Flyout/EvidenceFlyout.js";
import { MONOLITH_PERMISSIONS } from "../../../constants";
import { Input } from "@monolith-forensics/monolith-ui";

const buttonStyle = {
  minWidth: "150px",
  padding: "2px 5px",
  position: "relative",
  display: "flex",
  alignItems: "center",
};

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 0.85rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  resize: none;
  outline: none;
  background-color: ${({ theme }) => theme.palette.background.default};
  transition: border 0.1s ease-in-out;

  font-family: ${({ theme }) => theme.typography.fontFamily};

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.text.secondary}50;
  }
`;

const CompleteAuditModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo = {},
}) => {
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    handleClose();

    AuditsAPI.completeAudit({
      uuid: defaultInfo.uuid,
    }).then((res) => {
      enqueueSnackbar("Audit has been completed", {
        variant: "success",
      });

      onSubmit();
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 550,
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 275px)",
            top: 20,
            overflowY: "auto",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large", fontWeight: 500 }}>
            Complete Audit
          </div>
          <div style={{ padding: "5px 20px" }}>
            <p>Are you sure you want to complete this audit?</p>
            <p>
              Once completed, audit records can no longer be updated within this
              audit.
            </p>
          </div>

          <div style={{ marginTop: 20 }}>
            <ToolBarItems
              submitText="Complete Audit"
              onSubmit={handleSubmit}
              onCancel={handleClose}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const AuditStatusChangeModal = styled(
  ({
    className,
    open,
    handleClose = () => {},
    onCancel = () => {},
    onSubmit = () => {},
    defaultInfo = {},
  }) => {
    const theme = useTheme();
    const [notes, setNotes] = useState("");
    const textareaRef = useRef(null);

    const { enqueueSnackbar } = useSnackbar();

    const buttonStyle = {
      fontWeight: 500,
      padding: "1px 3px",
      borderRadius: 4,
      color:
        defaultInfo.newStatus === "passed"
          ? theme.name === "DARK"
            ? "#32CD32"
            : theme.palette.success.main
          : defaultInfo.newStatus === "failed"
          ? theme.palette.error.main
          : theme.palette.text.secondary,
      backgroundColor:
        defaultInfo.newStatus === "passed"
          ? theme.name === "DARK"
            ? "#32CD3230"
            : theme.palette.success.main + "30"
          : defaultInfo.newStatus === "failed"
          ? theme.palette.error.main + "30"
          : theme.name === "DARK"
          ? "#212121"
          : "#dddddd",
    };

    const handleSubmit = () => {
      onSubmit({
        newStatus: defaultInfo.newStatus,
        notes: notes,
      });

      handleClose();
    };

    // Focus on textarea
    useEffect(() => {
      setTimeout(() => {
        if (open && textareaRef.current) {
          textareaRef.current.focus();
        }
      }, 50);
    }, [textareaRef.current, open]);

    // Submit on enter
    useEffect(() => {
      if (!open) return;

      const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          handleSubmit();
          setNotes("");
        }
      };

      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [open, handleSubmit]);

    return (
      <>
        <Modal
          open={open}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") handleClose();
          }}
          style={{ zIndex: 1400 }}
        >
          <div
            className={className}
            style={{
              marginTop: 20,
              marginBottom: 20,
              width: 550,
              backgroundColor: theme.palette.background.default,
              position: "fixed",
              left: "calc(50% - 275px)",
              top: 20,
              overflowY: "auto",
              padding: 20,
              outline: "none",
            }}
          >
            <div
              style={{ marginBottom: 15, fontSize: "large", fontWeight: 500 }}
            >
              Change Audit Item Status
            </div>
            <div className="message">
              Changing status of item{" "}
              <span className="object-number">{defaultInfo.object_number}</span>{" "}
              to{" "}
              <span className="new-status" style={buttonStyle}>
                {defaultInfo.newStatus}
              </span>
            </div>
            <div>
              <StyledTextarea
                ref={textareaRef}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Enter notes about the audit status of this item."
              />
            </div>

            <div style={{ marginTop: 20 }}>
              <ToolBarItems
                submitText="Update Status"
                onSubmit={handleSubmit}
                onCancel={() => {
                  handleClose();
                  setNotes("");
                  onCancel();
                }}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
)`
  .message {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 500;
    margin: 10px 0;

    .object-number {
      color: ${({ theme }) => theme.palette.text.primary};
      font-weight: 600;
    }

    .new-status {
      color: ${({ theme }) => theme.palette.text.primary};
      font-weight: 600;
      text-transform: capitalize;
    }
  }
`;

const AuditItem = styled(({ className, item, auditInfo, page, onUpdate }) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  const [showAuditStatusChangeModal, setShowAuditStatusChangeModal] =
    useState(false);
  const [status, setStatus] = useState(item.status);
  const [method, setMethod] = useState(item.method);
  const [evidenceFlyoutVisible, setEvidenceFlyoutVisible] = useState(false);
  const theme = useTheme();

  const buttonStyle = {
    fontWeight: 500,
    color:
      status === "passed"
        ? theme.name === "DARK"
          ? "#32CD32"
          : theme.palette.success.main
        : status === "failed"
        ? theme.palette.error.main
        : "#f0ad4e",
    backgroundColor:
      status === "passed"
        ? theme.name === "DARK"
          ? "#32CD3230"
          : theme.palette.success.main + "30"
        : status === "failed"
        ? theme.palette.error.main + "30"
        : "#f0ad4e30",
  };

  const itemTitle = item.object_number || "Unknown";
  const itemHref =
    item.object_type === "evidence"
      ? `/evidence/${item.object_uuid}/overview`
      : `/storage/${item.object_uuid}`;

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setMethod(newStatus === "pending" ? null : "manual");

    if (newStatus !== "pending") {
      setShowAuditStatusChangeModal(true);
    } else {
      onStatusChange({
        newStatus,
      });
    }
  };

  const onStatusChange = (values = {}) => {
    if (values.newStatus !== "pending") {
      AuditsAPI.createAuditLog({
        audit_uuid: auditInfo.uuid,
        object_uuid: item.object_uuid,
        passed_audit: values.newStatus === "passed",
        object_type: item.object_type,
        notes: values.notes,
      });
    }

    AuditsAPI.updateAuditItem({
      uuid: auditInfo.uuid,
      object_uuid: item.object_uuid,
      status: values.newStatus,
      method: values.newStatus === "pending" ? null : "manual",
    }).then(() => {
      onUpdate?.(
        {
          uuid: auditInfo.uuid,
          object_uuid: item.object_uuid,
          status: values.newStatus,
          method: values.newStatus === "pending" ? null : "manual",
          page,
        },
        item
      );
    });
  };

  useEffect(() => {
    setStatus(item.status);
    setMethod(item.method);
  }, [item]);

  const readOnly = !hasPermission(MONOLITH_PERMISSIONS.AUDITS_UPDATE);

  return (
    <>
      <div className={className}>
        <AuditStatusChangeModal
          open={showAuditStatusChangeModal}
          handleClose={() => setShowAuditStatusChangeModal(false)}
          onSubmit={onStatusChange}
          onCancel={() => {
            setStatus(item.status);
            setMethod(item.method);
          }}
          defaultInfo={{ ...item, auditInfo, newStatus: status }}
        />
        <div className="item-title">
          <Link to={itemHref}>
            {itemTitle}
            <ExternalLink size={14} style={{ marginLeft: 5 }} />
          </Link>
        </div>
        <div
          className="detail subtle cap no-select"
          title={`Audit Method\nThis is the audit method used for this item.\n(Manual, Scanned, None)`}
        >
          {method || "None"}
        </div>
        <div
          className="detail subtle"
          title={`Current Item Location\nThis is the current location of this audit item.\n(Office / Group / Location)`}
        >
          {item.location_path || "No Location"}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            className="detail subtle cap no-select"
            style={{
              display: "flex",
              cursor: "pointer",
              marginTop: 2,
              marginRight: 5,
            }}
            onClick={() => {
              setEvidenceFlyoutVisible(true);
            }}
          >
            <Maximize2 size={12} />
          </div>
          <div
            className="detail subtle cap no-select"
            title={
              `Audit Logs\n` +
              (item?.logs
                ? item?.logs
                    ?.map(
                      (log) =>
                        `${monolithMoment({ timestamp: log.created_on })} (${
                          log.created_by.full_name
                        }): ${log.notes}`
                    )
                    .join("\n")
                : "No Logs")
            }
            style={{
              display: "flex",
              alignItems: "center",
              gap: 5,
              marginTop: 2.5,
              width: "fit-content",
            }}
          >
            <ArticleOutlinedIcon style={{ fontSize: 14 }} />
            <div>{item?.logs?.length || 0}</div>
          </div>
        </div>
        <div className="action-button">
          <DropdownMenu
            menuItems={[
              {
                label: "Passed",
                value: 1,
                onClick: () => handleStatusChange("passed"),
              },
              {
                label: "Failed",
                value: 0,
                onClick: () => handleStatusChange("failed"),
              },
              {
                label: "Pending",
                value: null,
                onClick: () => handleStatusChange("pending"),
              },
            ]}
            title={"Action"}
            variant="contained"
            arrow={!(readOnly || auditInfo.is_complete)}
            buttonProps={{
              style: buttonStyle,
            }}
            disabled={readOnly || auditInfo.is_complete}
          >
            <span style={{ textTransform: "capitalize" }}>{status}</span>
          </DropdownMenu>
        </div>
        <div
          style={{
            bottom: 0,
            right: 0,
          }}
        ></div>
      </div>
      {evidenceFlyoutVisible && (
        <EvidenceFlyout
          isOpen={evidenceFlyoutVisible}
          setIsOpen={setEvidenceFlyoutVisible}
          handleClose={() => setEvidenceFlyoutVisible(false)}
          defaultEvidenceInfo={item}
        />
      )}
    </>
  );
})`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) =>
    theme.name === "DARK" ? "#2D2D2D" : "#fff"};
  box-shadow: ${({ theme }) => "3px 3px 5px 0px rgba(0,0,0,0.2)"};

  border-radius: 4px;
  padding: 18px;
  width: 320px;
  height: fit-content;
  position: relative;

  a {
    color: ${({ theme }) => theme.palette.text.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .action-button {
    position: absolute;
    top: 18px;
    right: 18px;
  }

  .item-title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
  }

  .detail {
    font-size: 0.75rem;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.primary};
    cursor: default;

    transition: color 0.1s ease-in-out;
  }

  .detail.subtle {
    color: ${({ theme }) => theme.palette.text.secondary};

    &:hover {
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }

  .cap {
    text-transform: capitalize;
  }

  .no-select {
    user-select: none;
  }

  .status-label {
    margin-bottom: 20px;
  }

  .info-icon {
    color: ${({ theme }) => theme.palette.text.secondary};

    &:hover {
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`;

const StyledBadge = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: 0.5px 1px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 300;

  margin-left: 5px;
`;

const FilterList = styled(({ className, filter }) => {
  const parsedFilter = filter.map((condition) => {
    if (condition.mode === "multi-select") {
      return {
        id: condition.id,
        label: condition.label,
        operator: condition.operator.name,
        value: [condition.displayValue.join(", ")],
      };
    }

    if (condition.mode === "text") {
      return {
        id: condition.id,
        label: condition.label,
        operator: condition.operator.name,
        value: condition.displayValue.map((v) => `"${v}"`),
      };
    }

    if (condition.mode === "date") {
      let value = condition.displayValue;
      if (condition.operator.operator === "isBetween") {
        value = value.map((v) => v.split("T")[0]);
        value.splice(1, 0, "And");
      } else {
        value = value.map((v) => v.split("T")[0]);
      }

      return {
        id: condition.id,
        label: condition.label,
        operator: condition.operator.name,
        value: value,
      };
    }
  });

  return (
    <div className={className}>
      {parsedFilter.map((condition) => {
        return (
          <div key={condition.id} className="filter-condition">
            <div className="filter-segment">{condition.label}</div>
            <div className="filter-segment">{condition.operator}</div>
            {condition.value.map((v) => (
              <div className="filter-segment">{v}</div>
            ))}
          </div>
        );
      })}
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
  user-select: none;

  .filter-condition {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2.5px;
  }

  .filter-segment {
    font-size: 0.75rem;
    font-weight: 300;
    cursor: default;

    padding: 2px 5px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.background.secondary};
    border: 1px solid ${({ theme }) => theme.palette.divider};
    color: ${({ theme }) => theme.palette.text.primary};

    font-weight: 400;
    cursor: default;
  }
`;

const AuditOverviewTab = styled(({ className, auditData }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  const onCompleteAudit = () => {
    queryClient.refetchQueries({
      queryKey: ["audit:list", { uuid: auditData.uuid }],
    });
  };

  const handleExportAuditReport = () => {
    const total_items = auditData?.totals?.reduce((a, b) => a + b.total, 0);

    enqueueSnackbar("Generating audit report, this may take a minute...", {
      variant: "info",
    });
    AuditsAPI.getAuditReport({
      uuid: auditData.uuid,
      date_format: getDateFormat({ isMoment: true, includeTime: true }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      total_items,
    }).then((res) => {
      const { signedUrl, filename, status } = res;
      if (status === "emailing") {
        enqueueSnackbar("Audit report is being emailed to you", {
          variant: "info",
        });
        return;
      }
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
    });
  };

  const handleExportAuditItemsList = () => {
    enqueueSnackbar("Generating audit items list, this may take a minute...", {
      variant: "info",
    });

    AuditsAPI.exportAuditItemsList({
      uuid: auditData.uuid,
      date_format: getDateFormat({ isMoment: true, includeTime: true }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
    });
  };

  if (!auditData) return null;

  return (
    <div className={className}>
      <div className="audit-details">
        <div className="header">
          <div className="title">Audit Details</div>
          <div className="action-menu">
            {!auditData.is_complete && (
              <div className="complete-audit-btn">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={!hasPermission(MONOLITH_PERMISSIONS.AUDITS_UPDATE)}
                  onClick={() => setShowCompleteModal(true)}
                  style={{
                    minWidth: "fit-content",
                    fontSize: 11,
                    padding: "3px 6px",
                  }}
                >
                  Complete Audit
                </Button>
              </div>
            )}
            <DropdownMenu
              menuItems={[
                {
                  label: "Export Audit Report",
                  render: (data) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/static/icons/${getIconForFile("temp.pdf")}`}
                        alt="icon"
                        style={{ height: "1.1rem" }}
                      />
                      <span>{data.label}</span>
                    </div>
                  ),
                  value: 1,
                  onClick: handleExportAuditReport,
                },
                {
                  label: "Export Item List",
                  render: (data) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/static/icons/${getIconForFile("temp.xlsx")}`}
                        alt="icon"
                        style={{ height: "1.1rem" }}
                      />
                      <span>{data.label}</span>
                    </div>
                  ),
                  value: 0,
                  onClick: handleExportAuditItemsList,
                },
              ]}
              title={"Actions"}
              variant="outlined"
              arrow
              contentProps={{
                align: "end",
              }}
            >
              <span style={{ textTransform: "capitalize" }}>Actions</span>
            </DropdownMenu>
          </div>
        </div>
        <CompleteAuditModal
          open={showCompleteModal}
          handleClose={() => setShowCompleteModal(false)}
          onSubmit={onCompleteAudit}
          defaultInfo={auditData}
        />
        <div className="details-grid">
          <div className="detail-item">
            <div className="detail-label">Status</div>
            <div className="detail-value">{auditData?.status}</div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Item Type</div>
            <div
              className="detail-value"
              style={{ textTransform: "capitalize" }}
            >
              {auditData?.item_type}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Total Items</div>
            <div className="detail-value">
              {auditData?.totals?.reduce((a, b) => a + b.total, 0)}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Pending</div>
            <div className="detail-value">
              {auditData?.totals?.find((t) => t.status === "pending")?.total ||
                0}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Passed</div>
            <div className="detail-value">
              {auditData?.totals?.find((t) => t.status === "passed")?.total ||
                0}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Failed</div>
            <div className="detail-value">
              {auditData?.totals?.find((t) => t.status === "failed")?.total ||
                0}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Assignee</div>
            <div className="detail-value">
              {auditData?.assigned_to?.full_name || "None"}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Created By</div>
            <div className="detail-value">{auditData.created_by.full_name}</div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Created On</div>
            <div className="detail-value">
              {monolithMoment({ timestamp: auditData.created_on })}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Start Date</div>
            <div className="detail-value">
              {auditData.start_date
                ? monolithMoment({ timestamp: auditData.start_date })
                : "None"}
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-label">Due Date</div>
            <div className="detail-value">
              {auditData.due_date
                ? monolithMoment({ timestamp: auditData.due_date })
                : "None"}
            </div>
          </div>
          {auditData.is_complete === 1 && (
            <div className="detail-item">
              <div className="detail-label">Completed By</div>
              <div className="detail-value">
                {auditData.completed_on
                  ? auditData.completed_by.full_name
                  : "None"}
              </div>
            </div>
          )}
          {auditData.is_complete === 1 && (
            <div className="detail-item">
              <div className="detail-label">Completed On</div>
              <div className="detail-value">
                {auditData.completed_on
                  ? monolithMoment({ timestamp: auditData.completed_on })
                  : "None"}
              </div>
            </div>
          )}
        </div>
        <div className="detail-item" style={{ marginBottom: 20 }}>
          <div className="detail-label">Filter</div>
          {auditData?.filter?.length > 0 ? (
            <FilterList filter={auditData.filter} />
          ) : (
            <div className="detail-value">No Filter - All Items Included</div>
          )}
        </div>
        <div className="detail-item" style={{ marginBottom: 0 }}>
          <div className="detail-label">Description</div>
          <div className="detail-value no-cap">
            {auditData.description || "No Description"}
          </div>
        </div>
      </div>
    </div>
  );
})`
  .audit-details {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 4px;
    padding: 18px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .action-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    .title {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 3fr));
    grid-gap: 20px;
    margin-bottom: 20px;
  }

  .detail-item {
  }

  .detail-label {
    font-size: 0.75rem;
    font-weight: 300;
    color: ${({ theme }) => theme.palette.text.secondary};
    cursor: default;
  }

  .detail-value {
    font-size: 0.85rem;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.primary};
    cursor: default;

    text-transform: capitalize;

    &.no-cap {
      text-transform: none;
    }
  }
`;

const AuditItems = styled(({ className, auditData }) => {
  const { uuid } = useParams();
  const queryClient = useQueryClient();
  const { currentUser } = usePermissions();
  const [currentTab, setCurrentTab] = useState("overview");
  const { scannedValue, setScannerMode } = useBarcodeScanner();

  const [query, setQuery] = useState({ uuid, pageSize: 50 });
  const auditItemContainerRef = useRef(null);

  const { data: scannedItem } = useQuery({
    queryKey: [
      "audit:items:list",
      { uuid, search: scannedValue, data_only: true },
    ],
    queryFn: () =>
      AuditsAPI.getAuditItems({ uuid, search: scannedValue, data_only: true }),
    enabled: !!scannedValue,
  });

  const {
    data: infiniteData,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["audit:items:list", query],
    queryFn: ({ pageParam }) => {
      return AuditsAPI.getAuditItems({ ...query, page: pageParam });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage, pages) => {
      if (firstPage.page - 1 === 0) return null;
      return firstPage.page - 1;
    },
    initialPageParam: 1,
    enabled: currentTab !== "overview",
    placeholderData: (data) => data,
  });

  const debouncedFetchNextPage = useDebouncedCallback((e) => {
    fetchNextPage();
  }, 50);

  const handleChange = (event, newGroup) => {
    if (newGroup !== null) {
      setCurrentTab(newGroup);
      setQuery({ ...query, status: newGroup });

      // Remove all cached pages except the first one
      queryClient.setQueryData(["audit:items:list", query], (data) => {
        if (!data) return null;
        return {
          pages: data.pages.slice(0, 1),
          pageParams: data.pageParams.slice(0, 1),
        };
      });
    }
  };

  // Detect scroll to bottom
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (
      scrollHeight - scrollTop <=
      clientHeight + 500 * infiniteData?.pages?.length
    ) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  const handleUpdate = (updateData = {}, item) => {
    queryClient.refetchQueries({ queryKey: ["audit:list", { uuid }] });
    queryClient.setQueryData(["audit:items:list", query], (data) => {
      if (!data) return null;
      const newPages = data?.pages.map((page) => {
        if (!currentTab) return page;
        if (currentTab === "all") {
          // Update Item In All Tab
          return {
            ...page,
            data: page?.data?.map((item) => {
              if (item.object_uuid === updateData.object_uuid) {
                return {
                  ...item,
                  status: updateData.status,
                  method: updateData.method,
                };
              }
              return item;
            }),
          };
        } else if (updateData.status === currentTab) {
          // Add Item To Current Tab List
          return {
            ...page,
            data: [...page?.data, { ...item, ...updateData }],
          };
        } else {
          // Remove Item From Current Tab List
          return {
            ...page,
            data: page?.data?.filter(
              (item) => item.object_uuid !== updateData.object_uuid
            ),
          };
        }
      });

      return {
        ...data,
        pages: newPages,
      };
    });
  };

  const handleSearch = (event) => {
    let searchText = event.currentTarget.value;

    if (
      event.code === "Enter" ||
      event.code === "NumpadEnter" ||
      searchText === ""
    ) {
      setQuery((q) => ({
        ...q,
        search: searchText === "" ? null : searchText,
      }));
    }
  };

  // detect if no scroll bar
  useEffect(() => {
    if (auditItemContainerRef.current) {
      const { scrollHeight, clientHeight } = auditItemContainerRef.current;
      if (scrollHeight <= clientHeight) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    }
  }, [infiniteData?.pages?.length]);

  // Handle Barcode Scan
  useEffect(() => {
    if (!!scannedItem && scannedItem?.length === 1) {
      const auditItem = scannedItem[0];
      if (auditItem.status !== "passed") {
        AuditsAPI.createAuditLog({
          audit_uuid: auditData.uuid,
          object_uuid: auditItem.object_uuid,
          passed_audit: true,
          object_type: auditItem.object_type,
          notes: "Item scanned",
        });

        AuditsAPI.updateAuditItem({
          uuid: auditData.uuid,
          object_uuid: auditItem.object_uuid,
          status: "passed",
          method: "scanned",
        }).then(() => {
          handleUpdate?.(
            {
              uuid: auditData.uuid,
              object_uuid: auditItem.object_uuid,
              status: "passed",
              method: "scanned",
            },
            auditItem
          );
        });
      }
    }
  }, [scannedItem, auditData?.uuid]);

  // Set Scanner Mode Back to Search and Navigate
  useEffect(() => {
    setScannerMode("scan_only");
    return () => {
      setScannerMode("search_and_navigate");
    };
  }, [setScannerMode]);

  const control = {
    value: currentTab,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <div className={className}>
      <div className="toggle-menu">
        <div
          className="scanner-status"
          title={`Scan audit items to automatically set their status to "Passed"\nBarcode\\QR Code must match the uuid or short-uuid of the item.`}
        >
          <CheckIcon size={16} />
          Scanner Enabled
        </div>
        <ToggleButtonGroup size="small" {...control} style={{ margin: "auto" }}>
          <ToggleButton
            value="overview"
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <span>Audit Overview</span>
          </ToggleButton>
          <ToggleButton
            value="all"
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <span>All</span>
            <StyledBadge>
              {auditData?.totals?.reduce((a, b) => a + b.total, 0)}
            </StyledBadge>
          </ToggleButton>
          <ToggleButton
            value="pending"
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <span>Pending</span>
            <StyledBadge>
              {auditData?.totals?.find((t) => t.status === "pending")?.total ||
                0}
            </StyledBadge>
          </ToggleButton>
          <ToggleButton
            value="passed"
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <span>Passed</span>
            <StyledBadge>
              {auditData?.totals?.find((t) => t.status === "passed")?.total ||
                0}
            </StyledBadge>
          </ToggleButton>
          <ToggleButton
            value="failed"
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <span>Failed</span>
            <StyledBadge>
              {auditData?.totals?.find((t) => t.status === "failed")?.total ||
                0}
            </StyledBadge>
          </ToggleButton>
        </ToggleButtonGroup>
        {currentTab !== "overview" && (
          <div className="search-input">
            <Input
              placeholder="Search Items"
              onKeyUp={handleSearch}
              defaultValue={query.search || ""}
              title="Enter value to search and press enter."
            />
          </div>
        )}
      </div>
      <div style={{ height: "calc(100vh - 300px)", width: "100%" }}>
        {currentTab === "overview" && auditData ? (
          <AuditOverviewTab auditData={auditData} />
        ) : (
          <div
            ref={auditItemContainerRef}
            className="audit-items"
            onScroll={handleScroll}
          >
            {!infiniteData && <div>Loading...</div>}
            {infiniteData?.pages?.map((page) => {
              return page?.data?.map((item) => {
                return (
                  <AuditItem
                    key={item.uuid}
                    item={item}
                    auditInfo={auditData}
                    page={page.currentPage}
                    onUpdate={handleUpdate}
                  />
                );
              });
            })}
          </div>
        )}
      </div>
    </div>
  );
})`
  .status-section {
    display: flex;
    justify-content: start;
    flex-direction: row;

    gap: 20px;
  }

  .stat-item {
    border-radius: 4px;
    padding: 18px;
    width: 175px;
    height: fit-content;
    position: relative;

    .status-value {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .toggle-menu {
    position: relative;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    display: flex;
    overflow-x: auto;

    height: 40px;

    .scanner-status {
      position: absolute;
      top: 5px;
      left: 0px;

      display: flex;
      align-items: center;
      gap: 5px;

      font-size: 0.75rem;
      font-weight: 500;
      color: ${({ theme }) => (theme.name === "DARK" ? "limegreen" : "green")};
      cursor: default;

      &:hover {
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }

    .search-input {
      position: absolute;
      top: 5px;
      right: 0px;

      height: 28px;
    }
  }

  .audit-items {
    padding: 5px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 3fr));
    grid-gap: 20px;
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
`;

export default AuditItems;

// External Library Imports
import { useMemo, useRef } from "react";
import { useSnackbar } from "notistack";
import { EmptyItem, Form, SimpleItem } from "devextreme-react/ui/form";

// Relative Imports
import Modal from "../../../Monolith-UI/Modal/Modal";
import CollapseSection from "../../../components/CollaspeSection";
import ClientsAPI from "../../../api/clients";

const CreateClientModal = ({
  isOpen,
  onSubmit = () => {},
  handleClose = () => {},
}) => {
  const { enqueueSnackbar } = useSnackbar();

  // Devextreme Form Refs
  const clientInfoRef = useRef(null);
  const contactInfoRef = useRef(null);

  const handleSubmit = async (event) => {
    if (
      clientInfoRef.current.instance.validate().isValid &&
      contactInfoRef.current.instance.validate().isValid
    ) {
      let formData = {
        ...clientInfoRef.current.instance.option().formData,
        ...contactInfoRef.current.instance.option().formData,
      };

      try {
        const result = await ClientsAPI.createClient({
          ...formData,
        });

        onSubmit(result);
        enqueueSnackbar(`Client Created.`, {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(
          `Error creating client: ${error.message || "Unknown error"}`,
          {
            variant: "error",
          }
        );
      }
    }
    handleClose();
  };

  const ClientInfoForm = useMemo(() => {
    return (
      <Form
        ref={clientInfoRef}
        colCount={2}
        labelLocation={"top"}
        label={{
          visible: true,
          alignment: "left",
        }}
      >
        <SimpleItem
          dataField="name"
          label={{ text: "Name" }}
          isRequired={true}
        />
        <SimpleItem dataField="title" label={{ text: "Title" }} />
        <SimpleItem dataField="organization" label={{ text: "Organization" }} />
        <SimpleItem dataField="email" label={{ text: "Email" }} />
        <SimpleItem
          dataField="office_number"
          label={{ text: "Office Number" }}
        />
        <SimpleItem
          dataField="mobile_number"
          label={{ text: "Mobile Number" }}
        />
        <SimpleItem dataField="unique_id" label={{ text: "Unique ID" }} />
        <EmptyItem />
        <SimpleItem
          dataField="notes"
          label={{ text: "Notes" }}
          colSpan={2}
          editorType="dxTextArea"
          editorOptions={{
            height: 135,
          }}
        />
      </Form>
    );
  }, [isOpen]);

  const ContactInfoForm = useMemo(() => {
    return (
      <Form
        ref={contactInfoRef}
        colCount={2}
        labelLocation={"top"}
        label={{
          visible: true,
          alignment: "left",
        }}
      >
        <SimpleItem dataField="address" label={{ text: "Address" }} />
        <SimpleItem dataField="city" label={{ text: "City" }} />
        <SimpleItem dataField="state" label={{ text: "State/Province" }} />
        <SimpleItem dataField="zipcode" label={{ text: "Postal Code" }} />
        <SimpleItem dataField="country" label={{ text: "Country" }} />
      </Form>
    );
  }, [isOpen]);

  return (
    <>
      <Modal
        open={isOpen}
        title="Create Client"
        onSubmit={handleSubmit}
        onCancel={handleClose}
        submitText="Create"
        showCloseButton={false}
      >
        <CollapseSection title="Client Information" visible={true}>
          {ClientInfoForm}
        </CollapseSection>
        <CollapseSection title="Mailing Address">
          {ContactInfoForm}
        </CollapseSection>
      </Modal>
    </>
  );
};

export default CreateClientModal;

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import {
  Button,
  Flyout,
  FlyoutHeader,
  FlyoutTitle,
} from "@monolith-forensics/monolith-ui";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EditIcon,
  TrashIcon,
  UserX,
} from "lucide-react";

import {
  DetailItem,
  DetailLabel,
  DetailValue,
  FlyoutContainer,
  HeaderContent,
} from ".";
import ContactsColumnDefs from "../Contacts/ContactsColumnDefs";
import ContactsAPI from "@/api/contacts";
import { DeleteContactModal, EditContactModal } from "@/components/Modals";
import { useTheme } from "styled-components";
import UnassignContactModal from "../Modals/UnassignContactModal";

interface ContactFlyoutProps {
  show: boolean;
  onClose?: () => void;
  defaultInfo: any;
  onEdit: () => void;
  onDelete: () => void;
  onNext: () => void;
  onPrevious: () => void;
  caseId?: number | undefined;
}

const MainDetails: FC<ContactFlyoutProps["defaultInfo"]> = ({
  defaultData,
}) => {
  return (
    <FlyoutContainer>
      <div className="event-details">
        {ContactsColumnDefs.filter((col) => col.dataField !== "notes").map(
          (col) => {
            const key = col.dataField;
            const label = col.caption;
            const value = col?.render
              ? col.render(defaultData)
              : defaultData[key];

            return (
              <DetailItem key={key}>
                <DetailLabel>{label}</DetailLabel>
                <DetailValue>{value}</DetailValue>
              </DetailItem>
            );
          }
        )}
        <DetailItem>
          <DetailLabel>Notes</DetailLabel>
        </DetailItem>
        <DetailValue>
          {defaultData.notes || "No notes for this contact"}
        </DetailValue>
      </div>
    </FlyoutContainer>
  );
};

const ContactFlyout: React.FC<ContactFlyoutProps> = ({
  show,
  onClose,
  defaultInfo,
  onEdit,
  onDelete,
  onNext,
  onPrevious,
  caseId,
}) => {
  const theme: any = useTheme();
  const queryClient = useQueryClient();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showUnassignModal, setShowUnassignModal] = useState<boolean>(false);
  const { data: itemData, isLoading } = useQuery({
    queryKey: ["contact", "item", defaultInfo.client_id],
    queryFn: () =>
      ContactsAPI.getContacts({ client_id: defaultInfo.client_id }),
    initialData: [defaultInfo],
    select: (res) => {
      return res[0];
    },
  });

  return (
    <Flyout open={show} onClose={() => onClose?.()}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <>
          <FlyoutHeader>
            <FlyoutTitle>{itemData.name}</FlyoutTitle>
            <HeaderContent
              style={{
                borderBottom: `1px solid ${theme.palette.divider}`,
                paddingBottom: 10,
              }}
            >
              <div>Contact</div>
              <Button
                size="xxs"
                variant="text"
                leftSection={<EditIcon size={14} />}
                color="primary"
                onClick={() => setShowEditModal(true)}
              >
                Edit
              </Button>
              <Button
                size="xxs"
                variant="text"
                leftSection={<TrashIcon size={14} />}
                color="error"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </Button>
              {!!caseId && (
                <Button
                  size="xxs"
                  variant="text"
                  color="orange"
                  leftSection={<UserX size={14} />}
                  onClick={() => setShowUnassignModal(true)}
                >
                  Unassign Contact
                </Button>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
                <Button
                  size="xxs"
                  variant="outlined"
                  onClick={() => onPrevious?.()}
                  style={{ padding: "2px 4px" }}
                >
                  <ChevronLeftIcon size={14} />
                </Button>
                <Button
                  size="xxs"
                  variant="outlined"
                  onClick={() => onNext?.()}
                  style={{ padding: "2px 4px" }}
                >
                  <ChevronRightIcon size={14} />
                </Button>
              </div>
            </HeaderContent>
          </FlyoutHeader>
          <MainDetails defaultData={itemData} />
        </>
      )}
      <EditContactModal
        onSubmit={() => {
          queryClient.invalidateQueries({
            queryKey: ["contact", "item", defaultInfo.client_id],
          });
          queryClient.invalidateQueries({
            queryKey: ["contacts:list"],
          });
          onEdit();
        }}
        show={showEditModal}
        onClose={() => setShowEditModal(false)}
        onCancel={() => setShowEditModal(false)}
        defaultInfo={itemData}
      />
      <DeleteContactModal
        show={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={() => {
          onDelete?.(); // Refetches queries in contact section
          setShowDeleteModal(false);
        }}
        defaultInfo={itemData}
      />
      <UnassignContactModal
        show={showUnassignModal}
        onCancel={() => setShowUnassignModal(false)}
        onClose={() => setShowUnassignModal(false)}
        onSubmit={() => {
          onDelete?.();
          setShowUnassignModal(false);
        }}
        defaultInfo={itemData}
        caseId={caseId}
      />
    </Flyout>
  );
};

export default ContactFlyout;

import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import { Button, useTheme } from "@mui/material";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";

import CasesApi from "../../api/cases";
import { usePermissions } from "../../hooks/usePermissions";
import CasesHeader from "./components/CasesHeader.js";
import CaseFlyout from "./components/CaseFlyout.js";

import { getDateFormat } from "../../utils/date-format";
import {
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import UserApi from "../../api/users/users.js";
import OfficesApi from "../../api/offices/index.js";
import ClientsAPI from "../../api/clients/index.js";
import Loader from "../../components/Loader.js";
import { useQueryFilter } from "../../Monolith-UI/QueryFilter/QueryFilter.js";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";

import { useSnackbar } from "notistack";
import synchronizeColumnState from "../../utils/synchronize-column-state.js";
import CreateCaseModal from "./components/CreateCaseModal.js";
import { addCustomFieldsToColumnDefs } from "../../components/Evidence/CustomFieldCell.js";
import { useNavigate } from "react-router-dom";
import CaseColumnDefs from "../../components/Cases/CaseColumnDefs.js";
import CaseDimensions from "../../components/Cases/CaseDimensions.js";
import { useDebounce, useDebouncedCallback } from "use-debounce";

import Table, { Column, useTable } from "../../Monolith-UI/Table/Table.js";
import { ItemTotal } from "./CaseEvidence/index";
import { BriefcaseIcon } from "lucide-react";
import { MONOLITH_PERMISSIONS } from "../../constants.js";
import { CustomField } from "../Settings/CustomFieldSettings/types";
import { Case, ColumnResize, Condition } from "./types/Cases";
import {
  PageData,
  TableColumn,
  TableColumnDef,
} from "@/Monolith-UI/Table/types/Table";
import { Input } from "@monolith-forensics/monolith-ui";

interface CasesQuery {
  query: {
    conditions?: Condition[];
    page?: number;
    pageSize?: number;
    search?: string | null;
    user_id?: number;
    order?: {
      field: string;
      sort: string;
    } | null;
  };
}

interface QueryFilterRef {
  clear: () => void;
}

interface UpdateCache {
  rowData: Partial<Case>;
  updateData:
    | {
        case_closed_date: string;
        case_status: string;
        case_status_id: number;
      }
    | {
        progress: string;
        progress_id: number;
      }
    | {};
}

const Cases = styled(({ className, stateStoreKey = "cases:list:query" }) => {
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState(
    localStorage.getItem("casesView") || "My Cases"
  );

  const { currentUser, hasPermission } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const [showCreatePopup, setShowCreatePopup] = useState(false);

  const queryClient = useQueryClient();
  const theme = useTheme();
  const queryFilter = useRef<QueryFilterRef>(null);
  const [pageSize, setPageSize] = useState(100);

  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [flyoutData, setFlyoutData] = useState<Case | null>(null);
  const [searchText, setSearchText] = useState<string | null>(
    localStorage.getItem(`${stateStoreKey}:searchText`)
  );
  const [debouncedSearchText] = useDebounce(searchText, 500);

  const table = useTable();

  const [columnState, setColumnState] = useState(() => {
    return synchronizeColumnState(
      CaseColumnDefs,
      JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
    );
  });

  const currentSort = useMemo(() => {
    let [sort] = columnState
      ?.filter((c) => c.sorting?.active)
      .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

    return sort;
  }, [columnState]);

  const [query, setQuery] = useState<CasesQuery | null>(null);
  const userID = currentView === "All Cases" ? null : currentUser.user_id;
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        stateStoreKey,
        {
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            user_id: userID,
            include_assignees: true,
          },
        },
      ],
      queryFn: ({ pageParam }) => {
        return CasesApi.query({
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            page: pageParam,
            user_id: userID,
            include_assignees: true,
          },
        });
      },
      getNextPageParam: (lastPage, pages) => {
        return lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage, pages) => {
        if (firstPage.page - 1 === 0) return null;
        return firstPage.page - 1;
      },
      initialPageParam: 1,
      enabled: !!query,
      placeholderData: (data) => data,
    });

  const records = data?.pages?.reduce((acc, page) => {
    return [...acc, ...page.data];
  }, []);

  const totalRecords = data?.pages?.[0]?.total || 0;

  const debouncedFetchNextPage = useDebouncedCallback(() => {
    fetchNextPage();
  }, 50);

  const { data: customFields } = useQuery({
    queryKey: ["cases:customFields:list"],
    queryFn: () => CasesApi.getCaseCustomAttributes({ fieldsOnly: true }),
  });

  useQueries({
    queries: [
      {
        queryKey: ["cases:types"],
        queryFn: () => CasesApi.getCaseTypes(),
        enabled: !!data,
      },
      {
        queryKey: ["cases:statuses"],
        queryFn: () => CasesApi.getCaseStatuses(),
        enabled: !!data,
      },
      {
        queryKey: ["cases:progress"],
        queryFn: () => CasesApi.getCaseProgress(),
        enabled: !!data,
      },
      {
        queryKey: [
          "users:list",
          { includeObservers: false, includeInactive: false },
        ],
        queryFn: () =>
          UserApi.getUsers({
            includeObservers: false,
            includeInactive: false,
          }),
        enabled: !!data,
      },
      {
        queryKey: ["offices:list"],
        queryFn: () => OfficesApi.getOffices(),
        enabled: !!data,
      },
      {
        queryKey: ["clients:list"],
        queryFn: () => ClientsAPI.getClients(),
        enabled: !!data,
      },
      {
        queryKey: ["clients:organizations"],
        queryFn: () => ClientsAPI.getOrganizations(),
        enabled: !!data,
      },
      {
        // Fetch custom fields boxes
        queryKey: ["cases:customFields:list"],
        queryFn: () => CasesApi.getCaseCustomAttributes({ fieldsOnly: true }),
        enabled: !!data,
      },
      {
        // Fetch custom fields values
        queryKey: ["case:custom_fields:values"],
        queryFn: () => CasesApi.getCaseCustomAttributes(),
        enabled: !!data,
      },
    ],
  });

  // update filter dimensions with custom fields
  const filterDimensions = useMemo(() => {
    if (customFields) {
      //update filter dimensions
      return [
        ...CaseDimensions,
        ...customFields.map((field: CustomField) => ({
          name: field.field_name,
          field: `custom_field_${field.field_id}`,
          id: `custom_field_${field.field_id}`,
          type:
            field.editor_type === "dateBox"
              ? "date"
              : field.editor_type === "dropDown" ||
                field.editor_type === "tagBox"
              ? "string"
              : "text",
          mode:
            field.editor_type === "dateBox"
              ? "date"
              : field.editor_type === "dropDown" ||
                field.editor_type === "tagBox"
              ? "multi-select"
              : "text",
          options: field.options
            ? JSON.parse(field.options as string).map((o: string) => ({
                option: o,
              }))
            : null,
          selectionDisplayName: field.options ? "option" : null,
          selectionIdField: field.options ? "option" : null,
        })),
      ];
    }
    return CaseDimensions;
  }, [customFields]);

  const handleFilter = (
    newFilter: { conditions: Condition[] },
    query: CasesQuery
  ) => {
    setQuery((q) => {
      return {
        query: {
          ...q?.query,
          ...newFilter,
          page: 1,
          pageSize,
          user_id: userID,
        },
      };
    });
  };
  const handleColumnVisibility = (
    column: TableColumnDef<Case>,
    visible: boolean
  ) => {
    setColumnState((cs) => {
      return cs.map((c) => {
        if (c.dataField === column.dataField) {
          return {
            ...c,
            visible,
          };
        }
        return c;
      });
    });
  };
  const handleExportCaseTable = () => {
    // show snackbar
    enqueueSnackbar("Exporting case table...", {
      variant: "info",
    });

    CasesApi.exportCaseList({
      query: {
        ...query?.query,
        order: query?.query?.order || currentSort,
        pageSize,
      },
      type: "xlsx",
      columns: columnState
        .filter((c) => c.visible !== false)
        .sort((a, b) => a.order - b.order)
        .map((c) => {
          return { dataField: c.dataField, header: c.caption, ...c };
        }),
      date_format: getDateFormat({ isMoment: true, includeTime: true }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      custom_date_format: getDateFormat({ isMoment: true, includeTime: false }),
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
      // remove snackbar
      el.remove();
    });
  };
  const handleReload = () => {
    queryClient.refetchQueries({
      queryKey: [
        stateStoreKey,
        {
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            user_id: userID,
          },
        },
      ],
    });
  };
  const handleClearFilters = () => {
    queryFilter.current && queryFilter.current.clear();
  };
  const handleSort = (field: string) => {
    const savedColumn =
      columnState?.find((svc) => field === svc.dataField) || {};
    const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

    let newOrder = null;

    // if sorted on a different column, reset the sort
    if (query?.query?.order?.field !== field) {
      newOrder = {
        field,
        sort: "asc",
      };
    }

    // otherwise rotate the sort options on the current column
    else {
      switch (order) {
        case "asc":
          newOrder = {
            field,
            sort: "desc",
          };
          break;
        case "desc":
          newOrder = null;
          break;
        default:
          newOrder = {
            field,
            sort: "asc",
          };
      }
    }

    let newCols = columnState?.map((c) => {
      if (c.dataField === field) {
        return {
          ...c,
          sorting: {
            active: newOrder ? true : false,
            direction: newOrder?.sort,
          },
        };
      }
      delete c.sorting;
      return c;
    });

    let newQuery = {
      query: {
        ...query?.query,
        order: newOrder,
      },
    };

    setColumnState(newCols);

    setQuery(newQuery);
  };
  const handleColumnReorder = (
    newOrder: { column: string; order: number }[]
  ) => {
    setColumnState((cs) => {
      return newOrder.map((o) => {
        return {
          ...cs.find((c) => c.dataField === o.column),
          order: o.order,
        };
      });
    });
  };
  const handleColumnResize = (e: ColumnResize) => {
    setColumnState((cs) => {
      return cs.map((c) => {
        const col = e.columns.find((col) => col.dataField === c.dataField);
        if (col) {
          return {
            ...c,
            width: parseInt(col.width.replace(/px/g, "")),
          };
        }
        return c;
      });
    });
  };
  const handleActionButtonClick = (rowData: Case) => {
    setIsFlyoutVisible(true);
    setFlyoutData(rowData);
  };
  const handleGetNextItem = (case_id: number) => {
    const current = records?.findIndex((i: Case) => i.case_id === case_id);
    const nextItem = records[current + 1] || records[0];

    setFlyoutData(nextItem);
  };
  const handleGetPrevItem = (case_id: number) => {
    const current = records?.findIndex((i: Case) => i.case_id === case_id);
    const prevItem = records[current - 1] || records[records.length - 1];

    setFlyoutData(prevItem);
  };
  const handleViewChange = (item: { text: string; value: number }) => {
    setCurrentView(item.text);
    setQuery((q) => {
      return {
        query: {
          ...q?.query,
          user_id: item.text === "All Cases" ? null : currentUser.user_id,
        },
      };
    });
  };
  const onCaseCreated = (result: Case) => {
    navigate(`/cases/${result.case_id}/overview`);
  };
  // Detect scroll to bottom
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (
      scrollHeight - scrollTop <=
      clientHeight + 100 * (data?.pages.length || 0)
    ) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };
  const updateCache = (
    updateDetails: UpdateCache = { rowData: {}, updateData: {} }
  ) => {
    const itemUUID = updateDetails.rowData.uuid;
    queryClient.setQueryData(
      [
        stateStoreKey,
        {
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            user_id: userID,
            include_assignees: true,
          },
        },
      ],
      (data: PageData<Case>) => {
        if (!data) return null;
        const newPages = data?.pages.map((page) => {
          return {
            ...page,
            data: page?.data?.map((item) => {
              if (item.uuid === itemUUID) {
                return {
                  ...item,
                  ...updateDetails.updateData,
                };
              }
              return item;
            }),
          };
        });

        return {
          ...data,
          pages: newPages,
        };
      }
    );
  };

  // Sync column state to local storage
  useEffect(() => {
    const localStorageStateValue = localStorage.getItem(stateStoreKey);
    let oldState = localStorageStateValue
      ? JSON.parse(localStorageStateValue)
      : {};
    localStorage.setItem(
      stateStoreKey,
      JSON.stringify({
        ...oldState,
        cols: columnState,
      })
    );
  }, [columnState]);

  // Add custom fields to column state
  useEffect(() => {
    if (!customFields) return;
    setColumnState((cs) => {
      return addCustomFieldsToColumnDefs(customFields, cs);
    });
  }, [customFields]);

  useEffect(() => {
    setQuery((q) => ({
      query: {
        ...q?.query,
        search: debouncedSearchText ? debouncedSearchText : null,
        page: 1,
      },
    }));
  }, [debouncedSearchText]);

  const { queryButton, conditions } = useQueryFilter({
    dimensions: filterDimensions.sort((a, b) => a.name.localeCompare(b.name)),
    onQuerySet: handleFilter,
    queryFilter: queryFilter,
    stateStoring: {
      enabled: true,
      type: "localStorage",
      storageKey: stateStoreKey,
    },
  });

  return (
    <div className={className}>
      <Helmet title="Cases" />
      <CasesHeader onChange={handleViewChange} />
      <div
        style={{
          display: "flex",
          flex: "initial",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          marginBottom: 10,
          marginTop: 10,
        }}
      >
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={!hasPermission(MONOLITH_PERMISSIONS.CASES_CREATE)}
          onClick={(e) => {
            setShowCreatePopup(true);
          }}
          style={{
            minWidth: "fit-content",
            fontSize: 11,
            padding: "3px 6px",
          }}
        >
          + New Case
        </Button>
        <div style={{ marginLeft: 10 }}>{queryButton}</div>
        <ItemTotal total={totalRecords || 0} Icon={BriefcaseIcon} />
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            minWidth: "fit-content",
          }}
        >
          <ComboButton
            type="multi-select"
            data={columnState.filter((c) => c.showInColumnChooser !== false)}
            displayField="caption"
            idField={"dataField"}
            selectedItems={columnState.filter((c) => c.visible !== false)}
            variant="outlined"
            closeOnSelect={false}
            showSearch={true}
            dropDownTitle={() => {
              return (
                <div
                  style={{
                    margin: "5px 0px",
                    padding: 3,
                    color: theme.palette.text.secondary,
                    display: "flex",
                    alignItems: "center",
                    minWidth: 200,
                  }}
                >
                  Select Columns
                </div>
              );
            }}
            onItemDeSelect={(item) => {
              handleColumnVisibility(item, false);
            }}
            onItemSelect={(item) => {
              handleColumnVisibility(item, true);
            }}
            textColor={theme.palette.text.secondary}
            title={"Select Columns"}
          >
            <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Export Table"}
            onClick={handleExportCaseTable}
          >
            <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Clear Filters"}
            onClick={handleClearFilters}
          >
            <FilterListOffIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={table.isCompact ? "Zoom In" : "Zoom Out"}
            onClick={() => table.toggleCompact()}
          >
            {table.isCompact && (
              <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
            )}
            {!table.isCompact && (
              <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
            )}
          </ComboButton>
          <ComboButton
            type="button"
            variant={"outlined"}
            textColor={
              table.isStriped
                ? theme.palette.primary.main
                : theme.palette.text.secondary
            }
            title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
            onClick={() => table.toggleStripes()}
          >
            <ReorderOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <Input
            placeholder="Search Cases"
            variant="outlined"
            height={30}
            defaultValue={searchText}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              const currentValue = e.currentTarget.value.trim();
              setSearchText(currentValue);

              localStorage.setItem(`${stateStoreKey}:searchText`, currentValue);
            }}
            style={{
              marginLeft: "10px",
              border: searchText
                ? `1px solid ${theme.palette.primary.main}`
                : "",
            }}
          />
        </div>
      </div>
      {data && customFields && (
        <>
          {<div>{conditions}</div>}
          <Table
            data={records || []}
            totalRecords={totalRecords || 0}
            reloadFn={handleReload}
            keyValue="case_id"
            tableInstance={table}
            onHeaderClick={(col: TableColumn<Case>) =>
              col?.sorting?.enabled === false ? null : handleSort(col.dataField)
            }
            onScroll={handleScroll}
            onRowUpdated={(e: UpdateCache) => updateCache(e)}
            onColumnReorder={handleColumnReorder}
            onColumnResize={handleColumnResize}
            showActionColumn={true}
            onActionButtonClick={handleActionButtonClick}
            focusedRow={flyoutData}
          >
            {columnState.map((col) => {
              return <Column key={col.dataField} {...col} />;
            })}
          </Table>
        </>
      )}
      {!(data && customFields) && <Loader message={"Retrieving Cases..."} />}
      {isFlyoutVisible && (
        <CaseFlyout
          setIsFlyoutVisible={setIsFlyoutVisible}
          setFlyoutData={setFlyoutData}
          flyoutData={flyoutData}
          handleGetNextItem={handleGetNextItem}
          handleGetPrevItem={handleGetPrevItem}
          reloadFn={handleReload}
          columnDefs={columnState}
        />
      )}
      <CreateCaseModal
        open={showCreatePopup}
        handleClose={() => setShowCreatePopup(false)}
        onSubmit={onCaseCreated}
      />
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

Cases.displayName = "Cases";

export default Cases;

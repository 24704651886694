import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useState } from "react";

import { Typography, useTheme } from "@mui/material";
import { getClients } from "../../api";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";

import Loader from "../../components/Loader";
import ClientOverview from "./ClientOverview";
import { usePermissions } from "../../hooks/usePermissions";
import TaskButton from "../../Monolith-UI/TaskButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import styled from "styled-components";
import { DeleteClientModal } from "./components/DeleteClientModal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Client } from "./ClientsSection";

const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  margin: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }

  svg {
    font-size: 18px;
    margin-right: 5px;
    padding-bottom: 2px;
  }
`;

const ClientDetails = styled(({ className }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const { client_id } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data: clientInfo, isLoading } = useQuery({
    queryKey: [
      "clients",
      "list",
      { client_id: client_id ? parseInt(client_id, 10) : null },
    ],
    queryFn: async () =>
      (
        await getClients({
          client_id: client_id ? parseInt(client_id, 10) : null,
        })
      )[0],
    placeholderData: (data) => data,
  });

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleClientUpdate = (newValues: Partial<Client>) => {
    queryClient.setQueryData(
      [
        "clients",
        "list",
        { client_id: client_id ? parseInt(client_id, 10) : null },
      ],
      (oldData: Client) => {
        let newData: Partial<Client> = {};

        for (const key of Object.keys(oldData) as Array<keyof Client>) {
          if (newValues[key] !== undefined && oldData[key] !== newValues[key]) {
            // come back to these... super weird TS error that makes no sense!
            newData[key] = newValues[key] as any;
          } else {
            newData[key] = oldData[key] as any;
          }
        }

        return newData;
      }
    );
  };

  if (isLoading) return <Loader />;

  return (
    <div className={className}>
      <Helmet>
        <title>Client Details | {clientInfo.name}</title>
      </Helmet>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          margin: "10px 0px",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: "fit-content",
          }}
        >
          <BackLink message={"Back to Clients"} />
          <div
            style={{
              marginLeft: 10,
              marginRight: 10,
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            {clientInfo.name || "Client Details"}
            <Typography color="textSecondary" variant="subtitle2">
              {clientInfo.organization || ""}
            </Typography>
          </div>
        </div>
        {hasPermission(MONOLITH_PERMISSIONS.CLIENTS_DELETE) && (
          <DeleteButton onClick={handleDelete}>
            <DeleteOutlineOutlinedIcon
              style={{
                color: theme.palette.error.main,
              }}
            />
            <div className="action-menu-label">Delete</div>
          </DeleteButton>
        )}
      </div>
      <ClientOverview
        clientInfo={clientInfo}
        handleClientUpdate={handleClientUpdate}
      />
      <DeleteClientModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        clientInfo={clientInfo}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1;

  overflow-y: auto;
  padding-right: 10px;
`;

const BackLink = ({ message }: { message: string }) => {
  const theme = useTheme();

  let linkTo = `/clients`;

  if (window.history?.state?.usr?.from?.includes("case:clients"))
    linkTo = `/cases/${window.history?.state?.usr?.case_id}/overview`;
  return (
    <Link
      style={{
        color: theme.palette.primary.main,
        fontSize: "small",
        marginLeft: "auto",
        marginBottom: 15,
      }}
      onMouseEnter={(event) => {
        event.currentTarget.style.textDecoration = "underline";
      }}
      onMouseLeave={(event) => {
        event.currentTarget.style.textDecoration = "none";
      }}
      to={linkTo}
    >
      <TaskButton
        style={{ outline: `1px solid ${theme.palette.divider}` }}
        title={message}
      >
        <KeyboardReturnOutlinedIcon style={{ fontSize: 15 }} />
      </TaskButton>
    </Link>
  );
};

export default ClientDetails;

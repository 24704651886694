import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

export async function getTasks(values = {}) {
  return await httpGet(`/tasks`, values);
}

export async function getUserTasks(user_id) {
  return await httpGet(`/users/${user_id}/tasks`);
}

export async function getTaskAssignments(
  values = { case_id: null },
  options = { is_complete: false }
) {
  let api = `/tasks/assignments`;

  if (values.case_id !== null) api += `?case_id=${values.case_id}&`;

  api += `is_complete=${options.is_complete}`;

  return await httpGet(api);
}

//NEW
export async function getTaskLists(values = {}) {
  return await httpGet(`/tasks/lists`, values);
}

export async function createTaskList(values = {}) {
  return await httpPost(`/tasks/lists`, values);
}

export async function createTaskListFromTemplate(
  values = { case_id: null, template_id: null, created_by_id: null }
) {
  return await httpPost(`/tasks/lists`, values);
}

export async function deleteTaskList(task_list_id) {
  return await httpDelete(`/tasks/lists/${task_list_id}`);
}

export async function reorderTaskLists(items) {
  return await httpPatch(`/tasks/lists/reorder`, items);
}

export async function reorderTasks(items) {
  return await httpPatch(`/tasks/reorder`, items);
}

export async function createTask(values = {}) {
  return await httpPost(`/tasks`, values);
}

export async function updateTask(task_id, values = {}) {
  return await httpPatch(`/tasks/${task_id}`, values);
}

export async function updateTasks(taskIDs, values = {}) {
  values.taskIDs = taskIDs;
  return await httpPatch(`/tasks/multiple`, values);
}

export async function updateTaskList(task_list_id, values = {}) {
  return await httpPatch(`/tasks/lists/${task_list_id}`, values);
}

export async function deleteTask(taskID) {
  return await httpDelete(`/tasks/${taskID}`);
}

export async function getTotalTaskTime(taskID) {
  return await httpGet(`/tasks/${taskID}/time-entries/total`);
}

export async function addUserTaskAssignment(
  task_id,
  values = { user_id: null }
) {
  return await httpPost(`/tasks/${task_id}/assignments`, values);
}

export async function removeUserTaskAssignment(
  task_id,
  values = { user_id: null }
) {
  return await httpDelete(
    `/tasks/${task_id}/assignments?user_id=${values.user_id}`
  );
}

//////NEW STUFF//////////

export async function getTaskStatus() {
  return await httpGet("/tasks/status-items");
}

export async function getTaskListTemplates(options = {}) {
  if (options.template_id) {
    delete options.template_id;
    return await httpGet(
      `/tasks/lists/templates/${options.template_id}`,
      options
    );
  }

  return await httpGet("/tasks/lists/templates", options);
}

export async function createTaskListTemplate(values) {
  return await httpPost(`/tasks/lists/templates`, values);
}

export async function deleteTaskListTemplate(template_id) {
  return await httpDelete(`/tasks/lists/templates/${template_id}`);
}

export async function updateTaskListTemplate(template_id, values = {}) {
  return await httpPatch(`/tasks/lists/templates/${template_id}`, values);
}

const TasksAPI = {
  getTasks,
};

export default TasksAPI;

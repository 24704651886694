import ContactsAPI from "@/api/contacts";
import { SelectBox } from "@monolith-forensics/monolith-ui";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";

interface OrganizationSelectorProps {
  defaultValue: any;
  queryKey: string;
  onChange?: (value: any) => void;
  error?: string;
}

type Organization = {
  organization: string;
};

const OrganizationSelector: FC<OrganizationSelectorProps> = ({
  defaultValue,
  queryKey,
  onChange,
  error,
}) => {
  const { data: organizations } = useQuery<Organization[]>({
    queryKey: [queryKey, "organizations"],
    queryFn: () => ContactsAPI.getOrganizations(),
  });

  const options = organizations?.map((organization) => ({
    label: organization?.organization,
    value: organization?.organization,
  }));

  return (
    <>
      <SelectBox
        defaultValue={defaultValue}
        onChange={onChange}
        error={error}
        size="sm"
        variant="outlined"
        label="Organization"
        placeholder="Select or enter an organization"
        data={options}
        clearable={true}
        searchable={true}
        allowCustomValue={true}
        openOnFocus={false}
      />
    </>
  );
};

export default OrganizationSelector;

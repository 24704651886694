import { safeParseJSON } from "../../../utils/File-parsers/safeParseJSON.js";

const resolveCustomFieldValue = (field, value) => {
  switch (field.editor_type) {
    case "textBox":
      return value;
    case "dateBox":
      return value;
    case "dropDown":
      return value;
    case "tagBox":
      return safeParseJSON(value);
    default:
      return value;
  }
};

const convertCustomFieldsToObject = (customFields) => {
  return customFields.reduce((acc, field) => {
    acc[`custom_attribute_${field.field_id}`] = resolveCustomFieldValue(
      field,
      field.value
    );
    return acc;
  }, {});
};

export default convertCustomFieldsToObject;

import RelayAPI from "../../../api/RelayAPI/index.js";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../../contexts/AuthContext.js";
import styled from "styled-components";
import { useEditor } from "@tiptap/react";
import getTipTapExtensions from "../../../Monolith-UI/RichTextEditor/extensions/index.js";
import getTipTapEditorProps from "../../../Monolith-UI/RichTextEditor/props.js";
import RTEditor from "../../../Monolith-UI/RTEditor/RTEditor.js";
import { useDebouncedCallback } from "use-debounce";

const DefaultInstructions = `
    <h3>Please use these guidelines when submitting your request:</h3>
    <p></p>
    <ol class="">
      <li class="">
        <p>
          Enter a name for your Relay request - this will help identify your
          request later.
        </p>
      </li>
      <li class="">
        <p>
          Enter as much detail as you can including your case number and
          description of your request.
        </p>
      </li>
      <li class=""><p>Attach any documents relevant to this request.</p></li>
      <li class="">
        <p>
          Add any evidence items that you want the forensics team to work on.
        </p>
      </li>
      <li class="">
        <p>
          Add any people associated with this request which may include
          subjects, witnesses, custodians, or victims.
        </p>
      </li>
      <li class="">
        <p>Submit your request once you have completed the form.</p>
      </li>
    </ol>
    <p></p>
    <p>
      Once your request is submitted your can start tracking its progress and
      using the comment system to communicate with the forensics team.
    </p>
    <p></p>
    <p>Thanks!</p>
`;

const EditorWrapper = ({
  content,
  onContentUpdate,
  handleImageUpload,
  readOnly,
}) => {
  const editor = useEditor({
    content: content,
    editable: !readOnly,
    extensions: getTipTapExtensions({
      handleImageUpload,
    }).filter((ext) => ext.name !== "image"),
    editorProps: getTipTapEditorProps({
      handleImageUpload,
    }),
    onUpdate: ({ editor }) => {
      onContentUpdate?.(editor.getHTML());
    },
  });

  return (
    <RTEditor
      editor={editor}
      showToolbar={!readOnly}
      style={{ maxWidth: 1000 }}
    />
  );
};

const Instructions = styled(({ className, relayInfo }) => {
  const queryClient = useQueryClient();

  const { currentUser } = useAuth();
  const tenant = currentUser.licenseInfo.tenant;

  const handleUpdateContent = (content) => {
    // Do not upload base64 data
    // Image updloads and edit will be handled by the image uploader
    if (content.includes('src="data:image/')) {
      const parseHTML = new DOMParser().parseFromString(content, "text/html");

      const images = parseHTML.querySelectorAll(".monolith-image");

      // clear base64 data
      images.forEach((img) => {
        img.src = "";
        img.classList = "monolith-image";
      });

      // convert to string
      content = parseHTML.body.innerHTML;
    }

    RelayAPI.updateRelayInstructions({
      html: content,
      tenant_uuid: relayInfo.tenant_uuid,
    }).then(() => {});

    queryClient.setQueryData(
      [
        "relayInfo",
        {
          cloud_tenant_id: tenant,
          include_logo: true,
          include_instructions: true,
        },
      ],
      ([old]) => {
        return [
          {
            ...old,
            tenant_instructions: content,
          },
        ];
      }
    );
  };

  const debouncedUpdate = useDebouncedCallback(handleUpdateContent, 700);

  return (
    <div className={className}>
      <div style={{ marginBottom: 15 }}>
        These are a set of instructions that will be shown to the requestor in
        Relay before the make any requests.
      </div>
      <EditorWrapper
        content={relayInfo.tenant_instructions || DefaultInstructions}
        onContentUpdate={debouncedUpdate}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default Instructions;

import styled from "styled-components";

export const FlyoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  h3 {
    margin: 0px;
    margin-bottom: 10px;
  }

  .event-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .trace-attributes-list {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
  }

  .attribute-type {
    font-weight: 550;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  color: ${(props) => props.theme.palette.text.secondary};
  font-weight: 500;
  margin-bottom: 5px;
`;

export const DetailItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DetailLabel = styled.div`
  font-weight: 500;
  margin-right: 8px;
  min-width: 125px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const DetailValue = styled.div`
  white-space: pre-wrap;
  max-height: 150px;
  overflow-y: auto;
`;

export const NavigationButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-left: auto;
`;

import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { deleteClient } from "../../../api/index.js";
import Modal from "../../../Monolith-UI/Modal/Modal.js";

export const DeleteClientModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  clientInfo,
}) => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    deleteClient(clientInfo.client_id).then(() => {
      navigate("/clients");
      enqueueSnackbar(`Client Deleted.`, {
        variant: "success",
      });
    });
    onSubmit();
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <Modal
      open={open}
      title={`Delete Client ${clientInfo.name}`}
      submitText="Delete Client"
      submitColor="error"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      width="400px"
    >
      <div
        style={{ margin: "20px 0px", display: "flex", flexDirection: "column" }}
      >
        {`Are you sure you want to delete "${clientInfo.name}"?`}
        <div>{`This action cannot be undone.`}</div>
      </div>
    </Modal>
  );
};

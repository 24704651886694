import {
  Divider,
  Tab,
  Tabs,
  Typography,
  useTheme,
  Checkbox,
  Button,
} from "@mui/material";
import { Button as MfButton } from "@monolith-forensics/monolith-ui";
import { SyntheticEvent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TagBox } from "devextreme-react/ui/tag-box";
import { getCaseDocs } from "../../../api/cases/docs";
import {
  ReportParamSections,
  ReportParams,
  ReportParamsProvider,
  useReportParams,
} from "./contexts/ReportParamsContext";
import Loader from "../../../components/Loader";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import CaseReportsAPI from "../../../api/cases/reports.js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Table, { Column } from "../../../Monolith-UI/Table/Table.js";
import ReportDetailsColumnDefs from "./ReportDetailsColumnDefs.js";
import ContactsAPI from "../../../api/contacts/index.js";
import MonolithNotesAPI from "../../../api/Notes/index.js";
import DetailItem from "../../../Monolith-UI/DetailItem.js";
import styled from "styled-components";
import { monolithMoment } from "../../../utils/date-format";
import TaskButton from "../../../Monolith-UI/TaskButton.js";
import CaseReportSummary from "./components/CaseReportSummary.js";
import CaseAnalysis from "./components/CaseAnalysis.js";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { usePermissions } from "../../../hooks/usePermissions";
import DocumentTemplatesAPI from "../../../api/DocumentTemplates/DocumentTemplates.js";
import SelectReportTemplateModal from "./components/SelectReportTemplateModal.js";
import SelectEvidenceModal from "./components/SelectEvidenceModal.js";
import EvidenceAPI from "../../../api/evidence/index.js";
import Pill from "../../../Monolith-UI/Pill/Pill.js";
import { SortAscIcon, SortDescIcon } from "lucide-react";
import CreateDocumentTemplateModal from "../../../components/Modals/CreateDocumentTemplateModal";
import { Evidence } from "@/pages/Evidence/types/Evidence";
import { ReportTemplate } from "./types/CaseReport";

export interface DocumentTemplate {
  uuid: string;
  name: string;
  created_on: string;
  updated_on: string;
  is_shared: false;
  description: string;
  original_name: string;
  size: number;
  mime_type: string;
  ext: string;
  created_by: {
    email: string;
    title: string;
    user_id: number;
    full_name: string;
    last_name: string;
    first_name: string;
  };
  download_url: string;
  file_name?: string;
}

interface DocumentTemplateQuery {
  currentPage: number;
  data: DocumentTemplate[];
  nextPage: number;
  pageCount: number;
  pageSize: number;
  total: number;
}

interface CaseReportExportData {
  report_id: number;
  case_report_id: number;
  case_id: number;
  report_name: string;
  uuid: string;
  created_by_id: number;
  created_on: string;
  report_parameters: string;
  is_ready: true;
  status: string;
  case_number: string;
  case_ref: string;
  storage_path: string;
  created_by: string;
  email: string;
  title: string;
  link: string;
}

const tabs = [
  {
    label: "Report Options",
    value: "reportOptions",
    tooltipText:
      "Use these options to customize a Monolith Case Report. Choose which sections to include and pick data that should be included with the report.",
  },
  {
    label: "Report Summary",
    value: "reportSummary",
    tooltipText:
      "Draft a summary that is unique to this report. If included, the report summary will display on the case summary page of the Monolith Case Report.",
  },
  {
    label: "Analysis",
    value: "analysis",
    tooltipText:
      "Draft an analysis narrative that is unique to this report. If included, this analysis draft will appear on the case analysis page of the Monolith Case Report.",
  },
  {
    label: "Create Report",
    value: "reports",
    tooltipText:
      "Create a Monolith Case Report with the selected options and data.  Monolith will create a Microsoft Word document populated with your selected case data.",
  },
];

const CustomTab = (props: {
  key: string;
  label: string;
  value: string;
  tooltipText: string;
}) => {
  return (
    <Tab
      {...props}
      disableRipple
      sx={{
        textTransform: "none",
        minWidth: 140,
        fontWeight: "inherit",
        mr: 1,
        py: "5px",
        marginRight: 0,
        alignItems: "flex-start",
      }}
    />
  );
};

const TabSection = styled(({ className, caseInfo }) => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState("reportOptions");

  const handleTabsChange = (
    event: SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setCurrentTab(value);
  };

  return (
    <div className={className}>
      <div
        style={{
          marginTop: 5,
          display: "flex",
          flexDirection: "column",
          borderRight: `1px solid ${theme.palette.divider}`,
          paddingRight: 0,
        }}
      >
        <Tabs
          indicatorColor="primary"
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentTab}
          variant="scrollable"
          orientation="vertical"
          TabIndicatorProps={{
            style: { transition: "none", bottom: "unset" },
          }}
        >
          {tabs.map((tab) => (
            <CustomTab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              tooltipText={tab.tooltipText}
            />
          ))}
        </Tabs>
        <div
          style={{
            marginTop: 20,
            padding: "0px 15px",
            display: "flex",
            alignItems: "center",
            color: theme.palette.text.secondary,
          }}
        >
          <Link to={`/cases/${caseInfo.case_id}/reports`}>
            <TaskButton
              style={{
                outline: `1px solid ${theme.palette.divider}`,
                display: "flex",
                alignItems: "center",
                padding: "5px 10px",
              }}
              title="Back to Reports"
            >
              <KeyboardReturnOutlinedIcon style={{ fontSize: 16 }} />
              <div style={{ marginLeft: 5 }}>Reports</div>
            </TaskButton>
          </Link>
        </div>
      </div>
      <div className="report-sections">
        {currentTab === "reportOptions" && (
          <ReportOptions caseInfo={caseInfo} />
        )}
        {currentTab === "reportSummary" && <CaseReportSummary />}
        {currentTab === "analysis" && <CaseAnalysis />}
        {currentTab === "reports" && <CreateReportSection />}
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  height: 0px;

  .report-sections {
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
  }
`;

const CreateReportSection = styled(({ className }) => {
  const theme = useTheme();
  const { case_report_id } = useParams();
  // assuming these report params will never be null for now, but there are safer ways to do typing here.
  const { caseInfo, reportParams, hasPermission } = useReportParams()!;
  const [isCreating, setIsCreating] = useState(false);

  const { data, isLoading } = useQuery<null | CaseReportExportData[]>({
    queryKey: ["cases:reports:exports", { case_report_id }],
    queryFn: () => CaseReportsAPI.getCaseReportExports({ case_report_id }),
    refetchInterval: isCreating ? 1000 : false,
  });

  const handleCreateExport = () => {
    setIsCreating(true);
    CaseReportsAPI.createCaseReportExport({
      case_id: caseInfo.case_id,
      case_report_id: caseInfo.case_report_id,
      utcOffset: new Date().getTimezoneOffset(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      report_parameters: JSON.stringify(reportParams),
    }).then((result) => {});
  };

  useEffect(() => {
    if (data && data.every((item) => !item.status.includes("Generating"))) {
      setIsCreating(false);
    }
  }, [data]);

  if (isLoading) return <Loader />;

  return (
    <div className={className}>
      <div style={{ marginBottom: 20, fontSize: "large" }}>Create Report</div>
      <div style={{ marginBottom: 20, color: theme.palette.text.secondary }}>
        Create a Monolith Case Report with the selected options and data.
        Monolith will create a Microsoft Word document populated with your
        selected case data.
      </div>
      <div
        style={{
          display: "flex",
          flex: "initial",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={!hasPermission()}
          onClick={(e) => {
            handleCreateExport();
          }}
          style={{
            minWidth: "fit-content",
            fontSize: 11,
            padding: "3px 6px",
          }}
        >
          + Create Report
        </Button>
      </div>
      <Table data={data ? data : null} totalRecords={0} keyValue="report_id">
        {ReportDetailsColumnDefs.map((col) => {
          return <Column key={col.dataField} {...col} />;
        })}
      </Table>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

const ReportOptions = styled(
  ({
    className,
    caseInfo,
  }: {
    className?: string;
    caseInfo: { case_id: string; case_report_id: string };
  }) => {
    const { usingTemplate } = useReportParams()!;

    return (
      <div className={className}>
        <div style={{ marginLeft: 10, marginBottom: 20 }}>
          Use these options to customize a Monolith Case Report. Choose which
          sections to include and pick data that should be included with the
          report.
        </div>
        <TemplateOptionsSection />
        {!usingTemplate && <ReportOptionsSection />}
        {!usingTemplate && <CoverPageOptions />}
        {!usingTemplate && <TOCOptions caseInfo={caseInfo} />}
        {!usingTemplate && <CaseSummaryOptions caseInfo={caseInfo} />}
        {!usingTemplate && <CaseAnalysisOptions />}
        <EvidenceOptions caseInfo={caseInfo} />
        {!usingTemplate && <CaseTaskOptions />}
        {!usingTemplate && <CaseNotesOptions />}
        {!usingTemplate && <CasePeopleOptions caseInfo={caseInfo} />}
        {!usingTemplate && <CaseActivityOptions />}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

const OptionsItem = ({
  title,
  section,
  itemDetail,
  showCheckbox = true,
  children,
}: {
  // validate these
  title: string;
  section: ReportParamSections;
  itemDetail?: string;
  showCheckbox?: boolean;
  children?: React.ReactNode;
}) => {
  const [show, setShow] = useState(false);
  const { reportParams, updateParams, hasPermission } = useReportParams()!;

  const hasChildren = !!children;

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          paddingRight: 20,
        }}
      >
        {showCheckbox ? (
          <Checkbox
            checked={reportParams[section]?.include ?? false}
            disabled={!hasPermission()}
            size="small"
            onChange={(event) => {
              updateParams(section, {
                include: event.target.checked,
              });
            }}
          />
        ) : (
          <div style={{ width: 0 }}></div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginLeft: 10,
          }}
          onClick={() => {
            setShow(!show);
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", userSelect: "none" }}
          >
            {title}
          </Typography>
          {hasChildren && (
            <KeyboardArrowDownIcon
              style={{
                transform: show ? "none" : "rotate(-90deg)",
                marginLeft: 10,
              }}
            />
          )}
        </div>
        {itemDetail && <div style={{ marginLeft: "auto" }}>{itemDetail}</div>}
      </div>
      {hasChildren && (
        <div
          style={{
            display: show ? "block" : "none",
            marginBottom: 10,
            padding: "15px 40px",
          }}
        >
          {children}
        </div>
      )}
      <Divider sx={{ m: 4 }} />
    </div>
  );
};

interface OptionsSubItemProps<S extends keyof ReportParams> {
  title: string;
  section: S; // Use generic here to constrain section
  optionKey: keyof ReportParams[S]; // Now optionKey will be correctly inferred
}

const OptionsSubItem = <S extends keyof ReportParams>({
  title,
  section,
  optionKey,
}: OptionsSubItemProps<S>) => {
  const { reportParams, updateParams, hasPermission, defaultParams } =
    useReportParams()!;

  // Explicitly casting the type for indexing
  const checked =
    reportParams[section]?.[optionKey] ??
    defaultParams[section]?.[optionKey] ??
    false;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        checked={checked as boolean}
        size="small"
        disabled={!hasPermission()}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newOption: Partial<ReportParams[S]> = {};
          const value = event.target.checked;

          // Type guard to ensure we're only assigning to boolean keys
          if (typeof value === "boolean") {
            newOption[optionKey] =
              value as ReportParams[S][keyof ReportParams[S]];
          }

          updateParams(section, newOption); // Update the parameters
        }}
      />
      <div>{title}</div>
    </div>
  );
};

const TemplateOptionsSection = () => {
  const theme = useTheme();
  const { reportParams, updateParams } = useReportParams()!;
  const { currentUser } = usePermissions();
  const section = "templateOpts";
  const queryClient = useQueryClient();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);

  const { data } = useQuery<null | DocumentTemplateQuery>({
    queryKey: [
      "document-templates",
      "list",
      { created_by_id: currentUser.user_id, include_shared: true },
    ],
    queryFn: () =>
      DocumentTemplatesAPI.get({
        created_by_id: currentUser.user_id,
        include_shared: true,
      }),
  });

  const savedTemplate = reportParams?.templateOpts?.selectedTemplate;

  const currentTemplate = data?.data?.find(
    (item) => item.uuid === savedTemplate?.uuid
  );

  const handleSelectTemplate = (data: ReportTemplate) => {
    updateParams(section, { selectedTemplate: data });
  };

  const handleAddTemplate = (data: ReportTemplate) => {
    queryClient.setQueryData<{ data: ReportTemplate[] }>(
      [
        "document-templates",
        "list",
        { created_by_id: currentUser.user_id, include_shared: true },
      ],
      (oldData) => {
        return { data: [...oldData?.data!, data] };
      }
    );
    updateParams(section, { selectedTemplate: data });
    setShowAddModal(false);
  };

  const handleRemoveTemplate = () => {
    updateParams(section, { selectedTemplate: null, includedEvidenceIDs: [] });
  };

  return (
    <OptionsItem
      title="Template Options"
      section={section}
      showCheckbox={false}
      itemDetail={""}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          margin: "auto",
        }}
      >
        <Divider />
        <div style={{ fontSize: 18, fontWeight: 600, margin: "15px 0px" }}>
          Select Template
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 15,
              width: "100%",
              alignContent: "center",
              alignItems: "center",
              padding: 20,
              borderRadius: 5,
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <MfButton
              color="primary"
              variant="contained"
              size="xs"
              onClick={() => setShowAddModal(true)}
            >
              + New Template
            </MfButton>
            <div
              style={{
                fontWeight: 500,
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              Upload and create a new template to use.
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 15,
              width: "100%",
              alignContent: "center",
              alignItems: "center",
              padding: 20,
              borderRadius: 5,
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <MfButton
              color="primary"
              variant="contained"
              size="xs"
              onClick={() => setShowSelectModal(true)}
            >
              Select Template
            </MfButton>
            <div
              style={{
                fontWeight: 500,
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              Select a template to use for this report.
            </div>
          </div>
        </div>
      </div>
      {currentTemplate && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 15,
            width: "100%",
            padding: 20,
            borderRadius: 5,
            margin: "auto",
            marginTop: 20,
          }}
        >
          <div
            style={{
              fontWeight: 500,
              fontSize: "1rem",
            }}
          >
            Currently Selected Template
          </div>
          <div
            style={{
              marginBottom: 10,
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            <div style={{ fontWeight: 600 }}>{currentTemplate.name}</div>
            <div
              style={{ color: theme.palette.text.primary, marginBottom: 10 }}
            >
              {currentTemplate.description}
            </div>
            <div style={{ color: theme.palette.text.secondary }}>
              Uploaded On:{" "}
              {monolithMoment({ timestamp: currentTemplate.created_on })}
            </div>
            <div style={{ color: theme.palette.text.secondary }}>
              Created By: {currentTemplate.created_by.full_name}
            </div>
            <div style={{ color: theme.palette.text.secondary }}>
              Original Name:{" "}
              {currentTemplate.original_name || currentTemplate.file_name}
            </div>
            <div style={{ marginTop: 10 }}>
              <MfButton size="xs" onClick={() => handleRemoveTemplate()}>
                Remove Template
              </MfButton>
            </div>
          </div>
        </div>
      )}
      {showAddModal && (
        <CreateDocumentTemplateModal
          show={showAddModal}
          onClose={() => setShowAddModal(false)}
          onCancel={() => setShowAddModal(false)}
          onSubmit={handleAddTemplate}
        />
      )}
      {data && (
        <SelectReportTemplateModal
          open={showSelectModal}
          onClose={() => setShowSelectModal(false)}
          onSubmit={handleSelectTemplate}
          defaultInfo={currentTemplate}
        />
      )}
    </OptionsItem>
  );
};

const ReportOptionsSection = ({ visible = true }) => {
  if (!visible) return null;
  return (
    <OptionsItem
      title="Report Options"
      section="reportOpts"
      showCheckbox={false}
    >
      <OptionsSubItem
        title="Include Report Header"
        section="reportOpts"
        optionKey="includeHeader"
      />
      <OptionsSubItem
        title="Include Report Footer"
        section="reportOpts"
        optionKey="includeFooter"
      />
    </OptionsItem>
  );
};

const CoverPageOptions = ({ visible = true }) => {
  if (!visible) return null;

  return (
    <OptionsItem title="Cover Page" section="coverPageOpts">
      <OptionsSubItem
        title="Include Organization Logo"
        section="coverPageOpts"
        optionKey="includeLogo"
      />
    </OptionsItem>
  );
};

const TOCOptions = ({
  caseInfo,
  visible = true,
}: {
  caseInfo: { case_id: string; case_report_id: string };
  visible?: boolean;
}) => {
  if (!visible) return null;

  return <OptionsItem title="Table of Contents" section="tocOpts" />;
};

const CaseSummaryOptions = ({
  caseInfo,
  visible = true,
}: {
  caseInfo: { case_id: string; case_report_id: string };
  visible?: boolean;
}) => {
  const { reportParams, updateParams, hasPermission } = useReportParams()!;
  const [caseDocs, setCaseDocs] = useState([]);
  const section = "caseSummaryOpts";

  const itemDetail = `${
    reportParams?.caseSummaryOpts?.includedDocumentIDs?.length || "0"
  } Documents Selected`;

  useEffect(() => {
    let isMounted = true;
    getCaseDocs({ case_id: caseInfo.case_id }).then((result) => {
      if (isMounted) setCaseDocs(result);
    });
    return () => {
      isMounted = false;
    };
  }, []);

  if (!visible) return null;

  return (
    <OptionsItem title="Case Summary" itemDetail={itemDetail} section={section}>
      <OptionsSubItem
        title="Include My Info"
        section={section}
        optionKey="includeMyInfo"
      />
      <OptionsSubItem
        title="Include Client Info"
        section={section}
        optionKey="includeClientInfo"
      />
      <OptionsSubItem
        title="Include Case Description"
        section={section}
        optionKey="includeCaseSynopsis"
      />
      <OptionsSubItem
        title="Include Report Summary"
        section={section}
        optionKey="includeReportSummary"
      />
      <OptionsSubItem
        title="Include Evidence List"
        section={section}
        optionKey="includeEvidenceList"
      />
    </OptionsItem>
  );
};

const CaseAnalysisOptions = ({ visible = true }) => {
  if (!visible) return null;

  return <OptionsItem title="Analysis" section="reportAnalysisOpts" />;
};

const EvidenceOptions = ({
  caseInfo,
}: {
  caseInfo: { case_id: string; case_report_id: string };
}) => {
  const { reportParams, updateParams } = useReportParams();
  const [showSelectEvidenceModal, setShowSelectEvidenceModal] = useState(false);
  const section = "evidenceOpts";

  const { data: evidence } = useQuery({
    queryKey: ["evidence", "list", { case_id: caseInfo?.case_id }],
    queryFn: () =>
      EvidenceAPI.getEvidence({
        case_id: caseInfo?.case_id,
      }),
  });

  const eItemSelections = reportParams?.evidenceOpts?.includedEvidenceIDs || [];

  const itemDetail = `${
    reportParams?.evidenceOpts?.includedEvidenceIDs?.length || "0"
  } Evidence Items Selected`;

  const sortOptions = [
    {
      value: "intake_date_asc",
      label: "Intake Date",
      icon: SortAscIcon,
      hint: "Oldest to Newest",
    },
    {
      value: "intake_date_desc",
      label: "Intake Date",
      icon: SortDescIcon,
      hint: "Newest to Oldest",
    },
    {
      value: "evidence_number_asc",
      label: `Evidence Number`,
      icon: SortAscIcon,
      hint: "Sort from A to Z",
    },
    {
      value: "evidence_number_desc",
      label: "Evidence Number",
      icon: SortDescIcon,
      hint: "Sort from Z to A",
    },
  ];

  const currentSortOption = sortOptions.find(
    (item) => item.value === reportParams.evidenceOpts.displayOrder
  );

  const handleSelectEvidence = (data: number[]) => {
    updateParams("evidenceOpts", {
      includedEvidenceIDs: data || [],
    });
  };

  const handleRemoveEvidenceSelection = (eItem: Evidence) => {
    updateParams("evidenceOpts", {
      includedEvidenceIDs: eItemSelections?.filter(
        (eItemID: number) => eItemID !== eItem.evidence_id
      ),
    });
  };

  return (
    <OptionsItem title="Evidence" itemDetail={itemDetail} section={section}>
      {showSelectEvidenceModal && (
        <SelectEvidenceModal
          open={showSelectEvidenceModal}
          onClose={() => setShowSelectEvidenceModal(false)}
          onSubmit={handleSelectEvidence}
          defaultInfo={{
            caseInfo,
            selectedItems: eItemSelections || [],
          }}
        />
      )}
      <OptionsSubItem
        title="Include Description"
        section={section}
        optionKey="includeDescription"
      />
      <OptionsSubItem
        title="Include Details"
        section={section}
        optionKey="includeDetails"
      />
      <OptionsSubItem
        title="Include Chain of Custody"
        section={section}
        optionKey="includeChainOfCustody"
      />
      <OptionsSubItem
        title="Include Acquisitions"
        section={section}
        optionKey="includeAcquisitions"
      />
      <OptionsSubItem
        title="Include Child Evidence"
        section={section}
        optionKey="includeChildEvidence"
      />
      <OptionsSubItem
        title="Include Photos"
        section={section}
        optionKey="includePhotos"
      />
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            gap: 10,
          }}
        >
          <div
            style={{
              fontWeight: 500,
              fontSize: "0.85rem",
            }}
          >
            Sort Evidence By:
          </div>
          <DropdownMenu
            menuItems={sortOptions}
            variant="contained"
            onChange={(e?: string) =>
              updateParams(section, { displayOrder: e })
            }
            arrow
            defaultValue={undefined}
            dropdownContent={undefined}
          >
            <>
              {currentSortOption?.icon && (
                <currentSortOption.icon size={14} style={{ marginRight: 7 }} />
              )}
              {currentSortOption?.label || "Select..."}
            </>
          </DropdownMenu>
        </div>
        <div
          style={{
            fontWeight: 500,
            fontSize: "0.85rem",
          }}
        >
          Select evidence items to include in the report.
        </div>
        <MfButton
          color="primary"
          variant="contained"
          size="xxs"
          onClick={() => setShowSelectEvidenceModal(true)}
        >
          Select Evidence
        </MfButton>
        {eItemSelections?.length > 0 ? (
          <div>
            <div
              className="eItem-selections"
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: 10,
                margin: "10px 0px",
              }}
            >
              {evidence
                ?.filter((eItem: Evidence) =>
                  eItemSelections.includes(eItem.evidence_id)
                )
                ?.map?.((eItem: Evidence, index: number) => (
                  <Pill
                    size="sm"
                    key={index}
                    showRemoveIcon
                    onRemove={() => handleRemoveEvidenceSelection(eItem)}
                  >
                    {eItem.evidence_number}
                  </Pill>
                ))}
            </div>
          </div>
        ) : (
          <div>No evidence items selected.</div>
        )}
      </div>
    </OptionsItem>
  );
};

const CaseTaskOptions = ({ visible = true }: { visible?: boolean }) => {
  const section = "tasksOpts";
  if (!visible) return null;

  return <OptionsItem title="Tasks Summary" section={section}></OptionsItem>;
};

const CaseNotesOptions = ({ visible = true }) => {
  const { reportParams, updateParams, hasPermission, caseInfo } =
    useReportParams()!;
  const section = "notesOpts";

  const itemDetail = `${
    reportParams?.notesOpts?.includedCaseNoteIDs?.length || "0"
  } Case Notes Selected`;

  const defaultQuery = [
    "case:notes",
    {
      case_id: caseInfo.case_id,
      is_folder: false,
      data_only: true,
      order: { field: "name", sort: "asc" },
    },
  ];

  const { data: caseNotes, isFetched } = useQuery({
    queryKey: defaultQuery,
    queryFn: () => MonolithNotesAPI.getNotes(defaultQuery[1]),
  });

  if (!isFetched) return null;

  const NoteItem = styled(({ className, note }) => {
    const objectName = note?.object_name ? note?.object_name : "";

    return (
      <div className={className}>
        <div className="note-title">{note.note_tag}</div>
        <DetailItem
          label="Created By"
          value={note?.created_by?.full_name || "N/A"}
        />
        <DetailItem
          label="Created On"
          value={
            note?.created_on
              ? monolithMoment({
                  timestamp: note.created_on,
                  includeTime: true,
                  includeZone: true,
                })
              : "N/A"
          }
        />
        <DetailItem label="Note ID" value={note?.uuid} />
        <DetailItem
          label="Linked To"
          value={`${note?.object_type} ${objectName}`}
        />
      </div>
    );
  })`
    .note-title {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .detail-label {
      min-width: 75px;
      width: 75px;
    }

    .detail-value {
      color: ${({ theme }) => theme.palette.text.secondary};
      text-transform: capitalize;
    }
  `;

  if (!visible) return null;

  return (
    <OptionsItem title="Case Notes" itemDetail={itemDetail} section={section}>
      <div style={{ width: 400, marginLeft: 35, marginTop: 10 }}>
        <div style={{ margin: "10px 0px" }}>
          Select Case Notes to Include in Report:
        </div>
        <TagBox
          dataSource={caseNotes}
          displayExpr="note_tag"
          valueExpr="case_note_id"
          disabled={!hasPermission()}
          value={reportParams[section].includedCaseNoteIDs}
          showClearButton={true}
          showDropDownButton={true}
          showSelectionControls={true}
          maxDisplayedTags={4}
          dropDownOptions={{
            maxHeight: "250px",
          }}
          itemRender={(data) => <NoteItem note={data} />}
          onValueChanged={(e) => {
            if (e.value.toString() === e.previousValue.toString()) return;
            updateParams(section, { includedCaseNoteIDs: e.value });
          }}
        />
      </div>
    </OptionsItem>
  );
};

const CasePeopleOptions = ({
  caseInfo,
  visible = true,
}: {
  caseInfo: { case_id: string; case_report_id: string };
  visible?: boolean;
}) => {
  const { reportParams, updateParams, hasPermission } = useReportParams();
  const section = "contactsOpts";

  const itemDetail = `${
    reportParams?.contactsOpts?.includedContactIDs?.length || "0"
  } Contacts Selected`;

  const { data: contacts } = useQuery({
    queryKey: [
      "contacts:list",
      {
        case_id: caseInfo.case_id,
      },
    ],
    queryFn: () => ContactsAPI.getContacts({ case_id: caseInfo.case_id }),
    placeholderData: (data) => data,
    initialData: [],
  });

  if (!visible) return null;

  return (
    <OptionsItem title="People" itemDetail={itemDetail} section={section}>
      <div style={{ width: 400, marginLeft: 35, marginTop: 10 }}>
        <div style={{ margin: "10px 0px" }}>
          Select Contacts to Include in Report:
        </div>
        <TagBox
          dataSource={contacts}
          displayExpr="name"
          valueExpr="client_id"
          disabled={!hasPermission()}
          value={reportParams[section].includedContactIDs}
          showClearButton={true}
          showDropDownButton={true}
          showSelectionControls={true}
          maxDisplayedTags={4}
          dropDownOptions={{
            maxHeight: "250px",
          }}
          itemRender={(data) => {
            return (
              <>
                <div>{data.name}</div>
                <div style={{ color: "slategray" }}>{data.contact_type}</div>
              </>
            );
          }}
          onValueChanged={(e) => {
            if (e.value.toString() === e.previousValue.toString()) return;
            updateParams(section, { includedContactIDs: e.value });
          }}
        />
      </div>
    </OptionsItem>
  );
};

const CaseActivityOptions = ({ visible = true }) => {
  if (!visible) return null;

  return <OptionsItem title="Activity Log" section="activityLogOpts" />;
};

const CaseReportDetails = styled(({ className }) => {
  const { case_id, case_report_id } = useParams();
  const reportId = parseInt(case_report_id || "");

  const { data: caseReportData } = useQuery({
    queryKey: ["cases", "reports", "details", { case_report_id: reportId }],
    queryFn: async () => {
      return (
        (await CaseReportsAPI.getCaseReports({ case_report_id: reportId }))
          ?.data?.[0] || null
      );
    },
  });

  if (!caseReportData) return <Loader />;

  return (
    <div className={className}>
      <ReportParamsProvider
        params={caseReportData?.report_parameters}
        caseInfo={{ case_id, case_report_id: reportId }}
      >
        <TabSection caseInfo={{ case_id, case_report_id }} />
      </ReportParamsProvider>
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default CaseReportDetails;
